import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

export const ExtraContentPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <LoadingPlaceholder
    id='extraContent'
    width={194}
    height={24}
  >
    <rect x="0" y="4" rx="0" ry="0" width="194" height="16" />
  </LoadingPlaceholder>
);

export const TextPlaceholder: React.FC<IContentLoaderProps> = ({ width }): React.ReactElement => (
  <LoadingPlaceholder
    id='text'
    width={width ?? 60}
    height={28}
  >
    <rect x="0" y="6" rx="0" ry="0" width="194" height="16" />
  </LoadingPlaceholder>
);
