import { styled } from 'styled-components';

export const ExtraContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    display: flex;
    flex-direction: row;
  }
`;

export const BodyWrapper = styled.div`
  padding: ${props => props.theme.spacerSizes.ss3};

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    width: 63%;
  }
`;

export const PreviewContent = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.black};
  padding: 11px 24px;
  display: block;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    padding: 11px 14px;
    display: inline-block;
  }
`;

export const AsideContentWrapper = styled.div`
  padding: ${props => props.theme.spacerSizes.ss3};
  border: 1px solid ${props => props.theme.colors.black};
  margin-top: 20px;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    margin-top: 0;
    width: 30%;
  }
`;

export const AsideTitleWrapper = styled.div``;
