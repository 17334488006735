import React, { useContext } from 'react';

import PageWrapper from '../../components/PageWrapper/pageWrapper';
import { userContext } from '../../context/userContext';

import {
  UserWrapper,
  UserDetails
} from './profile.styles';

const Profile = ():React.ReactElement => {
  const { user } = useContext(userContext);
  return (
    <PageWrapper title='My Profile'>
      <UserWrapper>
        <img src={user?.picture} alt={`${user?.name} avatar`} />
        <UserDetails>
          <p><b>n:</b> {user?.name}</p>
          <p><b>u:</b> {user?.nickname}</p>
          <p><b>e:</b> {user?.email}</p>
        </UserDetails>
      </UserWrapper>
    </PageWrapper>
  );
};

export default Profile;
