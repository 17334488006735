import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { DropdownPlaceholderWrapper } from './dropdownPlaceholders.styles';

export const DropdownTextPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <DropdownPlaceholderWrapper>
    <LoadingPlaceholder
      id='dropdownText'
      width={150}
      height={26}
    >
      <rect x="0" y="5" rx="0" ry="0" width="150" height="16" />
    </LoadingPlaceholder>
  </DropdownPlaceholderWrapper>
);
