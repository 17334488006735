import { styled } from 'styled-components';

export const TagWrapper = styled.button`
  padding: 3px 5px;
  background-color: ${props => props.theme.layout.secondary};
  border: 0px;
  font-size: ${props => props.theme.fontSizes.fs0};
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2px;
  font-weight: bold;

  span {
    padding-right: 5px;
  }

  &:hover {
    background-color: ${props => props.theme.layout.primary};
    color: ${props => props.theme.colors.white};
  }
`;
