import React, { useContext } from 'react';

import { Title, Overlay } from '@arcade/component-library';

import { OverlayContext } from '../../context/overlayContext';

import UserBadge from '../../components/UserBadge/userBadge';

import {
  Wrapper,
  Header
} from './pageWrapper.styles';

const PageWrapper = ({ children, title }: { children: React.ReactNode, title: string}):React.ReactElement => {
  const overlayValues = useContext(OverlayContext);

  return (
    <Wrapper>
      <Header>
        <Title tag='h1' extraClass='h2' text={title} />
        <UserBadge />
      </Header>

      {children}
      <Overlay
        size={overlayValues.size}
        open={overlayValues.open}
        title={overlayValues.title}
        onClose={overlayValues.hideOverlay}
        noClickOutside={overlayValues.noClickOutside}
      >
        {overlayValues.component as React.ReactElement}
      </Overlay>
    </Wrapper>
  );
};

export default PageWrapper;
