import { styled } from 'styled-components';

export const CtaWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['multiline'].includes(props)
})<{ multiline: boolean }>`
  display: flex;
  background-color: ${props => props.theme.colors.lightGrey};
  padding: 20px;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    display: flex;
    justify-content: center;
    flex-direction: row;
    ${props => (props.multiline && 'flex-direction: column; align-items: center;')};
  }
`;

export const PreContentwrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 13px;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
  padding-bottom: 0;
  }
`;

export const Pricing = styled.div`
  ${props => props.theme.headings.mediumBold};
  margin-right: 3px;
  padding-bottom: 4px;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
  margin-right: 8px;
}
`;

export const PreContent = styled.div`
  margin-right: 6px;
`;

export const InitialPrice = styled.div`
  margin-right: 4px;
  color: ${props => props.theme.colors.darkGrey};
  text-decoration: line-through;

@media (min-width: ${props => props.theme.breakpoints.mobileL}) {
  margin-right: 9px;
}
`;

export const PromotionContent = styled.div`
  margin-right: 0;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: 2px 4px;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    margin-right: 35px;
  }
`;

export const PostContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
