import React, { useState, useEffect } from 'react';

import type { MenuAccordion as MenuAccordionProps } from './MenuAccordion.types';

import { Icon } from '../../atoms/Icon';

import {
  MAccordionWrapper,
  MAccordionTrigger,
  MAccordionContent,
  MAccordionContentInner
} from './menuAccordion.styles';

/**
 *
 * @param {string} label Accordion item main label.
 * @param {React.ReactNode} children Content of the Accordion item.
 * @param {boolean} [open=false] Set accordion itme isOpen.
 * @param {(o:boolean) => void} [onChange] Callback on change.
 * @param {boolean} [noBorder=false] Remove bottom border.
 *
 */

export const MenuAccordion = ({ label, children, open = false, onChange, id, testid = 'accordiom-item', noBorder = false }:MenuAccordionProps):React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOnChange = (accOpen:boolean):void => {
    if(onChange) onChange(accOpen);
    setIsOpen(accOpen);
  };

  return (
    <MAccordionWrapper data-testid={testid} noBorder={noBorder}>
      <MAccordionTrigger
        id={`${id}-trigger`}
        open={isOpen}
        aria-expanded={isOpen}
        aria-controls={`${id}-content`}
        onClick={() => handleOnChange(!isOpen)}
        noBorder={noBorder}
      >
        {label}
        <span>
          <Icon glyph='arrow_right' width='12' />
        </span>
      </MAccordionTrigger>
      <MAccordionContent id={`${id}-content`} role='region' aria-labelledby={`${id}-trigger`} open={isOpen} noBorder={noBorder}>
        <MAccordionContentInner>
          {children}
        </MAccordionContentInner>
      </MAccordionContent>
    </MAccordionWrapper>
  );
};
