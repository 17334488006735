import { styled } from 'styled-components';

import { fontBasic } from '../../../styles/mixins';

export const OptionsWrapper = styled.div`
  margin-top: 5px;
`;

export const OptionsGroup = styled.div`
  margin-bottom: 5px;
`;

export const SelectValue = styled.p`
  ${({ theme }) => fontBasic(theme.fontSizes.fs1, theme.lineHeights.lh1)}
  color: ${props => props.theme.colors.darkGrey};

  span {
    color: ${props => props.theme.layout.primary};
  }
`;
