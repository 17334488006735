import { Client } from '../../apiConfig';

export const SettingsApi = (client: Client) => ({
  getSetting: ({ token }: { token: string }) => {
    return client.GET('/api/settings', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
});
