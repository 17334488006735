import React, { createContext } from 'react';

export type OverlaySizes = 'small' | 'big';

export interface OverlayContextInterface {
  component: React.ReactNode | null
  size: OverlaySizes
  open: boolean
  showOverlay: (_comp:React.ReactNode, _title: string, _noOverlayClickOff?: boolean, _size?:OverlaySizes) => void
  hideOverlay: () => void
  noClickOutside: boolean
  title: string
}

export const initialValue:OverlayContextInterface = {
  open: false,
  component: null,
  size: 'small',
  showOverlay: () => null,
  hideOverlay: () => null,
  noClickOutside: false,
  title: ''
};

export const OverlayContext = createContext<OverlayContextInterface>(initialValue);
OverlayContext.displayName = 'OverlayContext';
