import React, { useEffect, useState, useRef } from 'react';

import { executeOnKeyDown } from '../../../utils';
import { Icon } from '../../atoms/Icon';
import { CountryFlag } from '../../atoms/CountryFlag';

import type { Codes } from '../../atoms/CountryFlag/countryFlag.types';

import type { ChannelSelector as ChannelSelectorProps } from './channelSelector.types';

import {
  SelectorWrapper,
  SelectorLink,
  SelectorFlag,
  SelectorLabel,
  SelectorIcon,
  SelectorDrop,
  DropItem,
  SelectedIcon
} from './channelSelector.styles';

export const ChannelSelector = ({ selected, territories, onChange }:ChannelSelectorProps):React.ReactElement => {
  const [territory, setTerritory] = useState<Codes>(selected);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const FirstItemRef = useRef<HTMLAnchorElement>(null);
  const selectDropdownRef = useRef<HTMLDivElement>(null);
  const selectLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setTerritory(selected);
  }, [selected]);

  const toggleTerritories = (e:React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
    if (menuOpen) FirstItemRef.current?.focus();
  };

  const changeTerritory = (selected:string): void => {
    if (onChange) onChange(selected);
    setMenuOpen(false);
  };

  const territoryName = (code:string): string => {
    const selectedTerritory = territories.find(ter => ter.code === code);
    return selectedTerritory?.name ? selectedTerritory.name : code;
  };

  useEffect(() => {
    const handleClose = (e:React.MouseEvent | MouseEvent) => {
      if (menuOpen && selectDropdownRef.current && !selectDropdownRef.current.contains(e.target as Node) && selectLinkRef.current && !selectLinkRef.current.contains(e.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.body.addEventListener('mousedown', handleClose);
    return () => {
      document.body.removeEventListener('mousedown', handleClose);
    };
  }, [menuOpen]);

  return (
    <SelectorWrapper role="navigation" aria-label="Region Navigation">
      <SelectorLink href='#open-country-select' role="button" data-testid="selector-trigger" tabIndex={0} onClick={toggleTerritories} onKeyDown={(e:React.KeyboardEvent<HTMLAnchorElement>) => executeOnKeyDown(e) && toggleTerritories} aria-label={`Choose region, current region is ${territoryName(territory)}`} aria-expanded={menuOpen} data-toggle="dropdown" data-target="region-dropdown" ref={selectLinkRef}>
        <SelectorFlag><CountryFlag territory={territory} /></SelectorFlag>
        <SelectorLabel>Region</SelectorLabel>
        <SelectorIcon open={menuOpen}><Icon glyph="arrow_down" title="Arrow down fill" /></SelectorIcon>
      </SelectorLink>
      <SelectorDrop id="region-dropdown" data-testid="selector-dropdown" open={menuOpen} ref={selectDropdownRef}>
        <ul>
          {territories.map((ter, i) => {
            const isSelected = ter.code === territory;
            return (
              <DropItem key={ter.code}>
                <a role="button" tabIndex={ter.code === territory ? -1 : 0} {...(i === 0 && { ref: FirstItemRef } )} onClick={() => changeTerritory(ter.code)} onKeyDown={e => executeOnKeyDown(e) && changeTerritory(ter.code)} className={isSelected ? 'selected' : ''}>
                  {isSelected && <SelectedIcon><Icon glyph='check_line' /></SelectedIcon>}
                  <CountryFlag territory={ter.code} />
                  <span>{ter?.name ? ter.name.toUpperCase() : ter.code}</span>
                </a>
              </DropItem>
            );
          })}
        </ul>
      </SelectorDrop>
    </SelectorWrapper>
  );
};
