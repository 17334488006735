import { styled, css } from 'styled-components';

export const DropdownPlaceholderWrapper = styled.span`
  svg {
    ${({ theme }) => css`
      height: clamp(${theme.lineHeights.lh3}, 3vw, ${theme.lineHeights.lh4});
      width: auto;
    `}
  }
`;
