import React, { useState } from 'react';

import { Icon } from '../../atoms/Icon';

import type { Accordion as AccordionProps } from './accordion.types';
import AccordionTitlePlaceholder from './placeholders/accordionTitlePlaceholder';

import {
  AccordionWrapper,
  AccordionItem,
  AccordionIcon,
  AccordionContent,
} from './accordion.styles';

/**
 *
 * @param {string[]} titles An array of titles for each accordion.
 * @param {string} name Accordion name for accessibility.
 * @param {number} [selected] Open element on start.
 * @param {JSX.Element[]} children Array of contents for each accordion.
 * @param {boolean} [multiple=false] Allow multiple accordion to be open.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const Accordion = ({ titles, name, selected, children, multiple = false, testid = 'accordion', parentIsLoading = false }:AccordionProps):React.ReactElement => {
  const [itemsSelected, setItemsSelected] = useState<{ open: boolean }[]>(titles.map((title, i) => ({ open: i === selected })));

  const handleSelectItem = (position?:number):void => {
    const obj = itemsSelected.map((item, i) => multiple
      ? ({ open: i === position ? !item.open : item.open })
      : ({ open: !item.open && i === position }));

    setItemsSelected(obj);
  };

  return (
    <AccordionWrapper data-testid={`${testid}-wrapper`}>
      { titles.map((title, i) => (
        <AccordionItem key={`${name}-accordion-${i}`} data-testid={`${testid}-item`}>
          <h3>
            <button
              data-testid={`${testid}-header`}
              aria-expanded={itemsSelected[i].open}
              aria-controls={`${name}-sect${i}`}
              id={`${name}-acc${i}`}
              onClick={() => handleSelectItem(i)}
              disabled={parentIsLoading}
            >
              <span>
                {parentIsLoading ? <AccordionTitlePlaceholder /> : title}
                <AccordionIcon open={itemsSelected[i].open}>
                  <Icon glyph='arrow_down' title={`chevron ${itemsSelected[i].open ? 'down' : 'right'}`} width='16px' />
                </AccordionIcon>
              </span>
            </button>
          </h3>
          <AccordionContent
            data-testid={`${testid}-content`}
            id={`${name}-sect${i}`}
            role="region"
            aria-labelledby={`${name}-acc${i}`}
            hidden={!itemsSelected[i].open}
          >
            {children[i]}
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionWrapper>
  );
};
