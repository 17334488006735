import { styled } from 'styled-components';

export const BreadcrumbsWrapper = styled.div`
  color: ${ props => props.theme.colors.darkGrey };
  line-height: 21px;

  span {
    position: relative;
    bottom: 1px;
    margin: 0 5px;
    font-size: 11px;
  }
`;

export const Breadcrumb = styled.div`
  display: inline-block;
`;

export const BreadcrumbLink = styled.a`
  color: ${ props => props.theme.colors.darkGrey };
  font-size: ${ props => props.theme.fontSizes.fs1 };
  font-weight: 400;
  text-decoration: none;

  :visited {
    color: ${ props => props.theme.colors.darkGrey };
  }

  &[href]:hover {
    text-decoration: underline;
  }
`;

export const LastBreadcrumb = styled(BreadcrumbLink)`
  color: ${ props => props.theme.colors.red };

  :visited {
    color: ${ props => props.theme.colors.red };
  }
`;
