import React from 'react';

import type { BlockList as BlockListProps } from './blockList.types';
import { RowColumns } from '../RowColumns';
import { Icon, Title, RichText } from '../../atoms';

import {
  BlockListWrapper,
  BlockListDiv,
  BlockListText,
} from './blockList.styles';

/**
 *
 * @param {string} heading Provides a heading.
 * @param {{icon:string, title:string, description:string}} rowData Provides a data object.
 * @param {string} subheading Provides a subheading.
 */

export const BlockList = ({ heading, rowData, subheading, testid = 'block' }: BlockListProps): React.ReactElement => {
  return (
    <BlockListWrapper>
      <Title text={heading} tag='h2' extraClass='h1'/>
      {subheading && <RichText >
        <div data-testid={`${testid}-subheading`} dangerouslySetInnerHTML={{ __html: subheading }} />
      </RichText>}
      <RowColumns rowColSpan={rowData.length}>
        {rowData.map(row => (
          <BlockListDiv key={row.title} data-testid="block-item">
            <Icon glyph={row.icon} />
            <BlockListText>{row.title}</BlockListText>
            <RichText>
              <div data-testid={`${testid}-description`} dangerouslySetInnerHTML={{ __html: row.description }} />
            </RichText>
          </BlockListDiv>
        ))}
      </RowColumns>
    </BlockListWrapper>
  );
};
