import React from 'react';

import { Image } from '../Image';

import type { ProductImage as ProductImageProps } from './productImage.types';
export const MULTIPLIER_PORTRAIT = 1.3235;
export const MULTIPLIER_SQUARE = 1;
export const MULTIPLIER_LANDSCAPE = 0.75;
/**
 * Responsive product image which will render a `<picture>` with two images (1x and 2x) per media query. Mobile first strategy.
 *
 * @param {import('./productImage.types').ImageSrc} image Original image data.
 * @param {string} title Image's title to be added as alt tag to the image.
 * @param {number} imgWidth Default image width (mobile first).
 * @param {number} imgHeight Default image height (mobile first).
 * @param {{ imgWidth: number, mediaMinWidth: string }[]} variants Additional image sizes and min-width media query (big to small).
 * @param {import('./productImage.types').OrientationTypes} [orientation='portrait'] Aspect ratio of the image ('portrait' | 'landscape' | 'square')
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 * @param {import('../Image/image.types').ObjectFit} [fit = 'cover'] How to fit the image into the container
 *
 */

export const ProductImage = ({ image, title, imgWidth, srcSetWidths, sizes, orientation = 'portrait', loading = 'lazy', parentIsLoading = false, testid = 'product-image', fit = 'cover' }:ProductImageProps): React.ReactElement => {
  const multiplier = {
    portrait: 1.3535135135,
    square: 1,
    landscape: 0.75
  }[orientation];

  const imgHeight = Math.round(imgWidth * multiplier);

  return (
    <Image
      testid={`${testid}-image`}
      title={title}
      image={image}
      { ...(!!srcSetWidths && { srcSetWidths }) }
      { ...(!!sizes && { sizes }) }
      imageWidth={`${imgWidth}`}
      imageHeight={`${imgHeight}`}
      fit={fit}
      loading={loading}
      parentIsLoading={parentIsLoading}
    />
  );
};
