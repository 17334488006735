import { styled, css } from 'styled-components';

export const SearchBarFormWrapper = styled.form`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

export const InputWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['suggestionsOpen'].includes(props)
})<{ suggestionsOpen?: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: ${props => props.theme.colors.lightestGrey};
  border: 1px solid ${props => props.theme.colors.lightGrey};

  ${props => !!props.suggestionsOpen && css`
    border: 0;
    border-radius: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: ${props => props.theme.colors.white};
    border-top: 1px solid ${props => props.theme.colors.black};
    border-left: 1px solid ${props => props.theme.colors.black};
    border-right: 1px solid ${props => props.theme.colors.black};
  `}

`;

export const SearchIconWrapper = styled('span').withConfig({
  shouldForwardProp: props => !['grey'].includes(props)
})<{ grey?: boolean }>`
  margin-left: 20px;
  margin-right: 20px;
  line-height: 0;

  ${({ grey }) => grey && css`
    color: ${props => props.theme.colors.darkGrey};
  `}
`;

export const TextInput = styled('input').withConfig({
  shouldForwardProp: props => !['suggestionsOpen'].includes(props)
})<{ suggestionsOpen?: boolean }>`
  width: 94%;
  width: calc(100% - 80px);
  height: 42px;
  box-sizing: border-box;
  border: 0;
  font-size: ${({ theme }) => theme.fontSizes.fs2};
  line-height: ${({ theme }) => theme.lineHeights.lh2};
  background-color: ${props => props.theme.colors.lightestGrey};

  ${props => !!props.suggestionsOpen && css`
    background-color: ${props => props.theme.colors.white};
  `}

  &:focus-visible {
    outline: none;
  }
`;

export const SearchBarControls = styled.div`
  display: flex;
  margin-right: 8px;

  svg {
    width: 16px;
    height: auto;
  }
`;

const button = `
  width: 44px;
  height: 42px;
  cursor: pointer;
  padding: 0;
  border: 0;
  background: none;
`;

export const ClearButton = styled.button`
  ${button}

  svg, path {
    fill: ${props => props.theme.colors.darkGrey};
  }
`;

export const SearchSubmitButton = styled.button`
  ${button}

  svg, path {
    fill: ${props => props.theme.colors.darkGrey};
  }
`;

export const ResultsPane = styled('div').withConfig({
  shouldForwardProp: props => !['suggestionsOpen', 'searchResultsLength'].includes(props)
})<{ searchResultsLength: number, suggestionsOpen: boolean }>`
  display: none;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndexes.zi1};
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.black};
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  ${({ suggestionsOpen, searchResultsLength }) => suggestionsOpen && searchResultsLength > 0 && css`
    display: block;
  `}
`;

export const Results = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 570px;
  overflow: hidden;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
`;

export const BottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  border-top: 1px solid ${props => props.theme.colors.lightGrey};

  > * {
    display: inline-block;
  }
`;

export const ViewAllButton = styled.button`
  height: 100%;
  padding: 0;
  background: none;
  border: 0;
  font-size: ${({ theme }) => theme.fontSizes.fs2};
  color: ${props => props.theme.colors.black};
  line-height: inherit;
  cursor: pointer;
  line-break: anywhere;
`;

export const PressEnterText = styled.div`
  align-self: flex-end;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 44px;
  margin-right: 20px;
  color: ${props => props.theme.colors.darkGrey};
  font-size: ${({ theme }) => theme.fontSizes.fs1};
`;
