import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: scroll;
`;

export const ContentWrapper = styled.div`
  overflow: scrool;
  display: block;
`;

export const DetailsWrapper = styled.div`
  margin: 20px;
  border: 1px solid ${props => props.theme.colors.lightestGrey};
  border-radius: 5px;
  padding: 20px;
  display: block;
`;

export const ActionsWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin-right: 20px;

    svg {
      padding-right: 10px;
    }
  }
`;
