import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const TabLargePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='tabLarge'
    width={98}
    height={24}
    {...props}
  >
    <rect x="0" y="2" rx="0" ry="0" width="98" height="20" />
  </LoadingPlaceholder>
);

export default TabLargePlaceholder;
