import { styled } from 'styled-components';
import { lighten } from 'polished';

export const PaginationItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: ${props => props.theme.spacerSizes.ss5};
`;

export const ThirdPart = styled.div`
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TwoThird = styled.div`
  width: 66.66%;
`;

export const HalfPart = styled.div`
  width: 50%;
`;

export const HR = styled.div`
  padding: ${props => props.theme.spacerSizes.ss3} ${props => props.theme.spacerSizes.ss5};

  hr {
    border-top: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
  }
`;
