import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { TitlePlaceholderWrapper, SubtitlePlaceholderWrapper } from './categoryTilePlaceholders.styles';

export const TitlePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <TitlePlaceholderWrapper>
    <LoadingPlaceholder
      id='category-tile-title'
      width={180}
      height={26}
    >
      <rect x="0" y="4" rx="0" ry="0" width="180" height="18" />
    </LoadingPlaceholder>
  </TitlePlaceholderWrapper>
);

export const SubtitlePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <SubtitlePlaceholderWrapper>
    <LoadingPlaceholder
      id='category-tile-subtitle'
      width={230}
      height={20}
    >
      <rect x="0" y="3" rx="0" ry="0" width="230" height="14" />
    </LoadingPlaceholder>
  </SubtitlePlaceholderWrapper>
);

export const CirclePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <LoadingPlaceholder
    id='category-tile-circle'
    width={50}
    height={50}
  >
    <rect x="0" y="0" rx="100" ry="100" width="50" height="50" />
  </LoadingPlaceholder>
);
