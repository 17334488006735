import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import {
  DescriptionPlaceholderWrapper,
  DeliveryEstimatePlaceholderWrapper,
  TotalPlaceholderWrapper,
  PricePlaceholderWrapper,
  IssuePricingPlaceholderWrapper,
  RemovePlaceholderWrapper,
  SubscriptionTypePlaceholderWrapper
} from './basketItemPlaceholders.styles';

export const BasketItemDescriptionPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <DescriptionPlaceholderWrapper>
    <LoadingPlaceholder
      id='basketItemDesc'
      width={100}
      height={21}
    >
      <rect x="0" y="4" rx="0" ry="0" width="150" height="14" />
    </LoadingPlaceholder>
  </DescriptionPlaceholderWrapper>
);

export const BasketItemDeliveryEstimatePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <DeliveryEstimatePlaceholderWrapper>
    <LoadingPlaceholder
      id='basketItemDeliveryEstimate'
      width={150}
      height={21}
    >
      <rect x="0" y="4" rx="0" ry="0" width="150" height="14" />
    </LoadingPlaceholder>
  </DeliveryEstimatePlaceholderWrapper>
);

export const BasketItemTotalEstimatePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <TotalPlaceholderWrapper>
    <LoadingPlaceholder
      id='basketItemTotalEstimate'
      width={30}
      height={21}
    >
      <rect x="0" y="4" rx="0" ry="0" width="150" height="14" />
    </LoadingPlaceholder>
  </TotalPlaceholderWrapper>
);

export const BasketItemPricePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <PricePlaceholderWrapper>
    <LoadingPlaceholder
      id='basketItemPrice'
      width={45}
      height={21}
    >
      <rect x="0" y="2" rx="0" ry="0" width="150" height="16" />
    </LoadingPlaceholder>
  </PricePlaceholderWrapper>
);

export const BasketItemIssuePricingPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <IssuePricingPlaceholderWrapper>
    <LoadingPlaceholder
      id='basketItemIssuePrice'
      width={135}
      height={18}
    >
      <rect x="0" y="3" rx="0" ry="0" width="150" height="12" />
    </LoadingPlaceholder>
  </IssuePricingPlaceholderWrapper>
);

export const BasketItemRemovePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <RemovePlaceholderWrapper>
    <LoadingPlaceholder
      id='basketItemRemove'
      width={66}
      height={21}
    >
      <rect x="0" y="3" rx="0" ry="0" width="66" height="14" />
    </LoadingPlaceholder>
  </RemovePlaceholderWrapper>
);

export const SubscriptionTypePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <SubscriptionTypePlaceholderWrapper>
    <LoadingPlaceholder
      id='subscriptionType'
      width={115}
      height={28}
    >
      <rect x="0" y="6" rx="0" ry="0" width="115" height="16" />
    </LoadingPlaceholder>
  </SubscriptionTypePlaceholderWrapper>
);
