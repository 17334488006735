import { styled, css } from 'styled-components';

const lightFocus = 'rgba(46, 114, 230, 0.07)';

export const TabButton = styled('button').withConfig({
  shouldForwardProp: props =>
    !['responsive'].includes(props)
})<{ selected: boolean, responsive: boolean, size: string }>`
  padding: 10px 30px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.beige};
  box-sizing: border-box;
  border-radius: ${props => props.theme.borderRadiuses.br2};
  box-shadow: 0 0 4px ${props => props.theme.colors.lightGrey};
  font-size: ${props => props.theme.fontSizes.fs4};
  text-transform: ${props => props.theme.fontDecor.fd4};
  color: ${props => props.theme.colors.black};
  line-height: ${props => props.theme.lineHeights.lh2};
  font-weight: ${props => props.theme.fontWeights.fw1};
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.layout.primary};
  }

  &:focus {
    border: 2px solid ${props => props.theme.colors.focus};
    box-shadow:
      0 32px 64px ${lightFocus},
      0 16px 32px ${lightFocus},
      0 8px 16px ${lightFocus},
      0 4px 8px ${lightFocus},
      0 2px 4px ${lightFocus},
      0 1px 2px ${lightFocus};
  }

  &:disabled {
    color: ${props => props.theme.colors.grey};
    border-color: ${props => props.theme.colors.lightGrey};
    cursor: default;
  }

  ${({ selected, theme }) => selected && css`
    border-color: ${theme.layout.primary};
  `}

  ${({ responsive }) => responsive && css`
    width: 100%;
  `}

  ${({ size, theme }) => size === 'small' && css`
    font-size: ${theme.fontSizes.fs1};
    line-height: ${theme.lineHeights.lh1};
    padding: 10px 20px;
  `}
`;
