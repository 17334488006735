import { styled, css } from 'styled-components';
import { darken } from 'polished';
import type { Button } from './button.types';

export const ButtonWrapper = styled('button').withConfig({
  shouldForwardProp: prop =>
    !['btnType', 'size', 'responsive', 'btnTheme'].includes(prop)
})<Button>`
  transition: all 200ms ease;
  background-color: ${props => props.theme.layout.primary};
  color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.layout.primary};
  border-radius: ${props => props.theme.borderRadiuses.br4};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 12px 30px;
  line-height: ${props => props.theme.lineHeights.lh2};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs2};

  &:hover {
    background-color: ${props => darken('0.1', props.theme.layout.primary)};
    cursor: pointer;
    border-color: ${props => darken('0.1', props.theme.layout.primary)};
    text-decoration: underline;
  }

  &:focus {
    background-color: ${props => props.theme.colors.focus};
    border-color: ${props => props.theme.colors.focus};
    text-decoration: underline;
    box-shadow:
      0 32px 64px rgba(46, 114, 230, 0.07),
      0 16px 32px rgba(46, 114, 230, 0.07),
      0 8px 16px rgba(46, 114, 230, 0.07),
      0 4px 8px rgba(46, 114, 230, 0.07),
      0 2px 4px rgba(46, 114, 230, 0.07),
      0 1px 2px rgba(46, 114, 230, 0.07);
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${props => props.theme.colors.lightestGrey};
    border: 1px solid ${props => props.theme.colors.lightestGrey};
    color: ${props => props.theme.colors.darkGrey};
    box-shadow: none;
    text-decoration: none;
  }

  ${props => props.btnType === 'outlined' && css`
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};
    border: 1px solid ${props => props.theme.colors.black};

    &:hover {
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.layout.primary};
      color: ${props => props.theme.colors.black};
    }

    &:focus {
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.focus};
      color: ${props => props.theme.colors.black};
      box-shadow: 0 1px 2px ${props => props.theme.colors.focus};
      box-shadow:
        0 32px 64px rgba(46, 114, 230, 0.07),
        0 16px 32px rgba(46, 114, 230, 0.07),
        0 8px 16px rgba(46, 114, 230, 0.07),
        0 4px 8px rgba(46, 114, 230, 0.07),
        0 2px 4px rgba(46, 114, 230, 0.07),
        0 1px 2px rgba(46, 114, 230, 0.07);
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.grey};
      color: ${props => props.theme.colors.darkGrey};
      box-shadow: none;
      text-decoration: none;
    }
  `}

  ${props => props.size === 'compact' && css`
    border-radius: ${props.theme.borderRadiuses.br4};
    height: 32px;
    line-height: ${props => props.theme.lineHeights.lh1};
    font-size: ${props => props.theme.fontSizes.fs1};
  `}

  ${props => props.btnType === 'text' && css`
    background-color: ${props => props.theme.colors.white};;
    color: ${props => props.theme.colors.black};
    border-radius: 4px;
    border: 0;
    transition: none;

  &:hover {
      background-color: ${props => props.theme.colors.white};
      border: 0;
    }

  &:focus {
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.focus};
      color: ${props => props.theme.colors.black};
      border: 2px solid ${props => props.theme.colors.focus};
      box-shadow:
        0 32px 64px rgba(46, 114, 230, 0.07),
        0 16px 32px rgba(46, 114, 230, 0.07),
        0 8px 16px rgba(46, 114, 230, 0.07),
        0 4px 8px rgba(46, 114, 230, 0.07),
        0 2px 4px rgba(46, 114, 230, 0.07),
        0 1px 2px rgba(46, 114, 230, 0.07);
      outline: none;
      transition: none;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: ${props.theme.colors.white};
      color: ${props => props.theme.colors.darkGrey};
      box-shadow: none;
      text-decoration: none;
    }
  `}

  ${props => props.responsive && css`
    border-radius: ${props.theme.borderRadiuses.br4};
    width: 100%;
  `}

  ${props => props.btnTheme && props.btnTheme === 'beigeWhite' && css`
    background-color: ${props => props.theme.colors.lightBeige};
    border: 1px solid ${props => props.theme.colors.lightBeige};

    &:hover {
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.beige};
      text-decoration: none;
    }

    &:focus {
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.beige};
      text-decoration: none;
    }
  `}
`;
