import { styled } from 'styled-components';

export const ProductListItemWrapper = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.lightestGrey};
  padding: ${props => props.theme.spacerSizes.ss4} 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding: ${props => props.theme.spacerSizes.ss5} 0;
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin: 0 0 ${props => props.theme.spacerSizes.ss3} 0;

  @media (min-width: ${props =>props.theme.breakpoints.mobile}) {
    margin: 0 ${props => props.theme.spacerSizes.ss5} ${props => props.theme.spacerSizes.ss4} 0;
  }

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    margin: 0 ${props => props.theme.spacerSizes.ss5} 0 0;
    width: 250px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  min-width: 0;
`;

export const Headings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: ${props =>props.theme.breakpoints.laptop}) {
    align-items: center;
  }
`;

export const HeadingFirst = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    align-items: flex-start;
  }

  @media (min-width: ${props =>props.theme.breakpoints.laptop}) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const HeadingLast = styled.div`
  flex-direction: row;
  align-items: center;
  display: none;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const SubTitle = styled.span`
  width: 100%;
  margin-bottom: ${props => props.theme.spacerSizes.ss2};

  @media (min-width: ${props =>props.theme.breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const Duration = styled.span`
  color: ${props => props.theme.colors.darkGrey};
  margin-left: 0;

  @media (min-width: ${props =>props.theme.breakpoints.desktop}) {
    margin-left: ${props => props.theme.spacerSizes.ss2};
  }
`;

export const ProductPrice = styled.span`
  font-weight: ${props => props.theme.fontWeights.fw2};
  color: ${props => props.theme.colors.black};

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    margin-right: ${props => props.theme.spacerSizes.ss4};
    width: auto;
  }
`;

export const ProductDescription = styled.div`
  padding-top: ${props => props.theme.spacerSizes.ss4};
  overflow: hidden;
`;

export const MobileCTA = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ProductPrice} {
    padding-right: ${props => props.theme.spacerSizes.ss4};
  }

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
