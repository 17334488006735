import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const FreeGiftTextPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='freeGiftText'
    width={160}
    height={21}
    {...props}
  >
    <rect x="0" y="4" rx="0" ry="0" width="160" height="14" />
  </LoadingPlaceholder>
);

export default FreeGiftTextPlaceholder;
