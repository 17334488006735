import React, { useState } from 'react';

import { OverlayContext, OverlayContextInterface, OverlaySizes } from '../context/overlayContext';

const OverlayProvider = ({ children } : { children: React.ReactNode}):React.ReactElement => {
  const [component, setComponent] = useState<React.ReactNode | null>(null);
  const [overlaySize, setOverlaySize] = useState<OverlaySizes>('small');
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [noClickOutside, setNoClickOutside] = useState<boolean>(false);

  const showModal = (comp:React.ReactNode, overlayTitle:string, noOverlayCloseViaClickOff = false, size:OverlaySizes = 'small') => {
    setComponent(comp);
    setTitle(overlayTitle);
    setNoClickOutside(noOverlayCloseViaClickOff);
    setOverlaySize(size);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setTimeout(() => setComponent(null), 1000);
  };

  const defaultValue:OverlayContextInterface = {
    component,
    size: overlaySize,
    open,
    showOverlay: showModal,
    hideOverlay: hideModal,
    noClickOutside,
    title
  };

  return (
    <OverlayContext.Provider value={defaultValue}>
      {children}
    </OverlayContext.Provider>
  );

};

export default OverlayProvider;
