import { styled } from 'styled-components';

export const MainLogo = styled.div`
  color: ${props => props.theme.layout.primary};
  border: 3px solid ${props => props.theme.layout.primary};
  display: flex;
  background-color: ${props => props.theme.colors.lightBeige};
  box-sizing: border-box;
  width: 60px;
  height: 55px;
  align-items: center;
  justify-content: center;
`;
