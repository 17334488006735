import React from 'react';
import { OrderSummary as OrderSummaryProps } from './orderSummary.types';
import { Icon, Title, PaymentCard, Button } from '../../atoms';
import { ExtraContentPlaceholder, TextPlaceholder } from './placeholders/orderSummaryPlaceholders';

import {
  OrderSummaryWrapper,
  ContentWrapper,
  ExtraContentWrapper,
  SummaryContent,
  Summary,
  SummaryRed,
  SummaryTotal,
  CardWrapper,
  ChildrenWrapper
} from './orderSummary.styles';

/**
 *
 * @param {string} title Title of teh summary.
 * @param {import('./orderSummary.types').Order} [orderData] Order data to show in the summary.
 * @param {number} itemsCount Number of items in the order.
 * @param {React.ReactNode} [children] Additional element (Button).
 * @param {import('../../atoms/PaymentCard/paymentCard.types').PaymentType[]} [availablePayments] Payment methods accepted.
 * @param {React.ReactNode} [extras] Additional functionality.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading data
 *
 */

export const OrderSummary = ({ title, orderData, itemsCount, children, testid = 'order-summary', availablePayments, extras, parentIsLoading = false }: OrderSummaryProps): React.ReactElement => {

  if (parentIsLoading) {
    return (
      <OrderSummaryWrapper data-testid={`${testid}-wrapper`}>
        <ContentWrapper>
          <Title text='' tag='h3' parentIsLoading />
        </ContentWrapper>
        <ExtraContentWrapper>
          <ExtraContentPlaceholder />
        </ExtraContentWrapper>
        <SummaryContent>
          <ContentWrapper>
            <Summary data-testid={`${testid}-subtotal`}>
              <span><TextPlaceholder width={63} /></span>
              <span><TextPlaceholder /></span>
            </Summary>
            <Summary data-testid={`${testid}-delivery`}>
              <span><TextPlaceholder width={60} /></span>
              <span><TextPlaceholder /></span>
            </Summary>
            <SummaryRed data-testid={`${testid}-promotional`}>
              <span><TextPlaceholder width={143} /></span>
              <span><TextPlaceholder /></span>
            </SummaryRed>
            <SummaryTotal data-testid={`${testid}-total`}>
              <span><TextPlaceholder width={86} /></span>
              <span><TextPlaceholder /></span>
            </SummaryTotal>
            <ChildrenWrapper>
              <Button disabled size='large' btnType='filled' responsive onClick={() => null} testid='summary'><Icon glyph='lock_solid' />&nbsp; Confirm and Pay</Button>
            </ChildrenWrapper>
          </ContentWrapper>
        </SummaryContent>
        <CardWrapper>
          {[1,2,3].map(num => (
            <PaymentCard key={num} type='visa' size='small' parentIsLoading />
          ))}
        </CardWrapper>
      </OrderSummaryWrapper>
    );
  }

  return (
    <OrderSummaryWrapper data-testid={`${testid}-wrapper`}>
      <ContentWrapper>
        <Title text={title} tag='h3' />
      </ContentWrapper>
      {extras && <ExtraContentWrapper>
        {extras}
      </ExtraContentWrapper>}
      <SummaryContent>
        <ContentWrapper>
          <Summary data-testid={`${testid}-subtotal`}>
            <span>Subtotal {`${ itemsCount > 1 ? '(' + itemsCount + ' items)' : '' }`}</span>
            <span>{orderData?.itemsTotal || 0}</span>
          </Summary>
          {orderData && <Summary data-testid={`${testid}-delivery`}>
            <span>Delivery</span>
            <span>{orderData?.shippingTotal || 'free'}</span>
          </Summary>}
          {orderData?.promotionalTotal ?
            <SummaryRed data-testid={`${testid}-promotional`}>
              <span>Promotional saving</span>
              <span>{orderData.promotionalTotal}</span>
            </SummaryRed> : ''
          }
          {orderData?.voucher && <SummaryRed data-testid={`${testid}-voucher`}>
            <span><Icon glyph='tag_solid' />&nbsp;{orderData.voucher.code}</span>
            <span>{orderData.voucher.total}</span>
          </SummaryRed>}
          <SummaryTotal data-testid={`${testid}-total`}>
            <span>Order Total</span>
            <span>{orderData?.orderTotal || 0}</span>
          </SummaryTotal>
          <ChildrenWrapper>
            {children}
          </ChildrenWrapper>
        </ContentWrapper>
      </SummaryContent>
      {availablePayments && availablePayments?.length > 0 && (
        <CardWrapper>
          {availablePayments.map(payment => (
            <PaymentCard key={payment} type={payment} size='small' />
          ))}
        </CardWrapper>
      )}
    </OrderSummaryWrapper>
  );
};
