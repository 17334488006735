import { styled } from 'styled-components';

export const TileWrapperLink = styled.a`
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 178px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.black};
  text-decoration: none;

  @media (min-width: ${props => `${props.theme.breakpoints.mobile}`}) {
    max-width: 225px;
    padding: 20px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.lightestGrey};
  }
`;

export const TileContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

export const TileImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const TileTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  margin-top: 10px;
  display: flex;
  min-height: 42px;
  align-items: center;

  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
`;

export const TilePrice = styled.div`
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  color: ${props => props.theme.layout.primary};
`;

export const AdditionalText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TileSubtitle = styled.div`
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  color: ${props => props.theme.colors.darkGrey};
  margin-bottom: 10px;
`;

export const TileStrikethrough = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  text-decoration: line-through;
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  padding-left: 5px;
`;

export const TileTextSubtitle = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
`;

export const FlagString = styled.div`
  background-color: ${props => props.theme.layout.primary};
  color: ${props => props.theme.colors.white};
  padding: 2px 4px;
  text-transform: uppercase;
  width: fit-content;
  margin-top: 5px;
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
`;

export const LoadingFlagString = styled(FlagString)`
  background-color: #f3f3f3;
`;

export const GiftFlag = styled.div`
  div {
    padding: 5px;
    margin: 0;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PriceText = styled.div`
  display: flex;
`;
