export const sortingOptions = [
  {
    id: 'default',
    label: 'Default'
  },
  {
    id: 'name:asc',
    label: 'Name - A-Z'
  },
  {
    id: 'name:desc',
    label: 'Name - Z-A'
  },
  {
    id: 'createdBy:asc',
    label: 'Created By - A-Z'
  },
  {
    id: 'createdBy:desc',
    label: 'Created By - Z-A'
  },
  {
    id: 'editedBy:asc',
    label: 'Edited By - A-Z'
  },
  {
    id: 'editedBy:desc',
    label: 'Edited By - Z-A'
  },
  {
    id: 'type:asc',
    label: 'Type - A-Z'
  },
  {
    id: 'type:desc',
    label: 'Type - Z-A'
  },
  {
    id: 'status:asc',
    label: 'Status - A-Z'
  },
  {
    id: 'status:desc',
    label: 'Status - Z-A'
  },
  {
    id: 'startDate:asc',
    label: 'Start Date - Oldest First'
  },
  {
    id: 'startDate:desc',
    label: 'Start Date - Newest First'
  },
  {
    id: 'endDate:asc',
    label: 'End Date - Oldest first'
  },
  {
    id: 'endDate:desc',
    label: 'End Date - Newest First'
  },
  {
    id: 'actions:asc',
    label: 'Actions - Ascending'
  },
  {
    id: 'actions:desc',
    label: 'Actions - Descending'
  },
];
