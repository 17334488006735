import React from 'react';

import { PaymentCard as PaymentCardProps } from './paymentCard.types';

import {
  Card
} from './paymentCard.styles';
import { CardPlaceholder } from './placeholders/paymentCardPlaceholders';

/**
 *
 * @param {import('./paymentCard.types').PaymentType} type Type of card to show.
 * @param {import('./paymentCard.types').Size} [size='large'] Size of the icon.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const PaymentCard = ({ type, testid='card-type', size = 'large', parentIsLoading = false }:PaymentCardProps): React.ReactElement => {
  if (parentIsLoading) {
    return (
      <CardPlaceholder size={size} />
    );
  }

  return (
    <Card type={type} size={size} data-testid={testid}/>
  );
};
