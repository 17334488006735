import React from 'react';

import { Button, Icon, Title } from '@arcade/component-library';

import {
  ActionWrapper,
  ActionBtn
} from './actionFields.styles';

const ActionField = ({ children, onRemove, actionName } : { children: React.ReactNode, onRemove: ()=> void, actionName: string }):React.ReactElement => {
  const handleRemove = (e:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>):void => {
    e.preventDefault();
    onRemove();
  };
  return (
    <ActionWrapper>
      <ActionBtn>
        <Title tag='h5' text={actionName} />
        <Button btnType='outlined' onClick={handleRemove} size='compact' ><Icon glyph='close' />remove</Button>
      </ActionBtn>
      {children}
    </ActionWrapper>
  );
};

export default ActionField;
