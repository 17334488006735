import React from 'react';
import type { CategoryTile as CategoryTileType } from './categoryTile.types';
import { Icon } from '../Icon';
import { TitlePlaceholder, SubtitlePlaceholder, CirclePlaceholder } from './placeholders/categoryTilePlaceholders';

import {
  LinkWrapper,
  IconWrapper,
  TextWrapper,
  Title,
  Subtitle
} from './categoryTile.styles';

/**
 * @param {import('../../../icons').IconsType} icon string indicating icon to show in circle
 * @param {string} title Title (large) text to display
 * @param {string | undefined} subtitle Subtitle (small) text to display
 * @param {string | undefined} link Link to go to when clicked
 */

export const CategoryTile = ({ icon, title, subtitle, link, parentIsLoading, testid = 'category-tile' }: CategoryTileType): React.ReactElement => {
  if (parentIsLoading) {
    return (
      <LinkWrapper data-testid={testid}>
        <CirclePlaceholder />
        <TextWrapper>
          <Title><TitlePlaceholder /></Title>
          <Subtitle><SubtitlePlaceholder /></Subtitle>
        </TextWrapper>
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper { ...(link ? { href: link } : {}) } tabIndex={0} data-testid={testid}>
      <IconWrapper><Icon glyph={icon} size='lg' /></IconWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TextWrapper>
    </LinkWrapper>
  );
};
