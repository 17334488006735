import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

export const TitlePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <LoadingPlaceholder
    id='carousel-title'
    width={250}
    height={34}
  >
    <rect x="22" y="0" rx="0" ry="0" width="250" height="34" />
  </LoadingPlaceholder>
);
