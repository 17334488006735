import { styled } from 'styled-components';

export const UserWrapper = styled.div`
  margin: 30px;
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  display: flex;
  flex-direction: row;
`;

export const UserDetails = styled.div`
  margin-left: 20px;
`;
