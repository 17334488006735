import React from 'react';
import { Icon } from '../Icon';
import { FreeGiftType } from './freeGift.types';

import { FreeGiftWrapper, FreeGiftContent } from './freeGift.styles';
import FreeGiftTextPlaceholder from './placeholders/freeGiftTextPlaceholder';

/**
 *
 * @param {import('./FreeGift.types').icon} icon Displays an icon.
 * @param {import('./FreeGift.types').iconTitle} iconTitle Provides a title for the icon.
 * @param {import('./FreeGift.types').description} description Provides text for the promotion.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const FreeGift = ({ icon, iconTitle, description, testid = 'freegift', parentIsLoading = false }:FreeGiftType): React.ReactElement => {
  return (
    <FreeGiftWrapper data-testid={`${testid}-wrapper`}>
      <FreeGiftContent>
        {parentIsLoading ? <FreeGiftTextPlaceholder /> : icon && (
          <>
            <Icon glyph={icon} title={iconTitle} width='24' />
            <p>{description}</p>
          </>
        )}
      </FreeGiftContent>
    </FreeGiftWrapper>
  );
};
