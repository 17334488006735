export type ImageType = {
  type?: string | null;
  baseUrl?: string;
  path?: string | null;
}

// Get image by image type, or return first image
export const getImage = (images?: ImageType[] | null, type?: string) => {
  if (!images || !images.length) return undefined;
  if (!type) return images[0];
  const imagesWithType = images.filter(image => image.type === type);
  if (!imagesWithType.length) return images[0];
  return imagesWithType[0];
};
