import React, { useContext } from 'react';
import { userContext } from '../../context/userContext';

import {
  UserBlock,
  UserAvatar
} from './userBadge.styles';

const UserBadge = ():React.ReactElement => {
  const { user } = useContext(userContext);

  if (!user) return <div />;

  return (
    <UserBlock>
      <UserAvatar>
        <img src={user?.picture} alt={user.name} />
      </UserAvatar>
      <p>{user.name}</p>
    </UserBlock>
  );
};

export default UserBadge;
