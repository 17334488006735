import React from 'react';

import PageWrapper from '../../components/PageWrapper/pageWrapper';

const MyCampaigns = ():React.ReactElement => {

  return (
    <PageWrapper title='My Campaigns'>
      <p>content</p>
    </PageWrapper>
  );
};

export default MyCampaigns;
