import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { TabsTitleLargePlaceholderWrapper } from './TabsPlaceholder.styles';

const TabsTitleLargePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <TabsTitleLargePlaceholderWrapper>
    <LoadingPlaceholder
      id='tabsTitleLarge'
      style={{ margin: '0 auto' }}
      width={180}
      height={34}
      {...props}
    >
      <rect x="0" y="3" rx="0" ry="0" width="180" height="28" />
    </LoadingPlaceholder>
  </TabsTitleLargePlaceholderWrapper>
);

export default TabsTitleLargePlaceholder;
