import { styled, css } from 'styled-components';

export const MAccordionWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['noBorder'].includes(props)
})<{ noBorder: boolean }>`
  width: 100%;
  box-sizing: border-box;
`;

export const MAccordionTrigger = styled('button').withConfig({
  shouldForwardProp: props => !['noBorder'].includes(props)
})<{ open: boolean, noBorder: boolean }>`
  width: 100%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  text-align: left;
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.theme.colors.beige};
  ${props => props.noBorder && css`
    border-bottom: 0;
  `}
  line-height: ${props => props.theme.lineHeights.lh1};
  font-size: ${props => props.theme.fontSizes.fs1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  text-transform: ${props => props.theme.fontDecor.fd4};
  cursor: pointer;

  span {
    width: 14px;
    
    svg {
      height: auto;
      color: ${props => props.theme.colors.black};
      font-size: ${props => props.theme.fontSizes.fs2};
      transition: transform 0.5s;
      ${props => props.open && css`
        transform: rotate(90deg);
      `}
    }
  }
`;

export const MAccordionContent = styled('div').withConfig({
  shouldForwardProp: props => !['noBorder'].includes(props)
})<{ open: boolean, noBorder: boolean }>`
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  background-color: ${props => props.theme.colors.lightestGrey};
  border-bottom: 1px solid ${props => props.theme.colors.beige};
  ${props => props.noBorder && css`
    border-bottom: 0;
  `}
  ${props => props.open && css`
    max-height: 1000px;
    opacity: 1;
  `}
`;

export const MAccordionContentInner = styled.div`
  padding: 20px 15px;
`;
