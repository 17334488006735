import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductOptionListItemDescriptionPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productOptionListItemDesc'
    width={125}
    height={18}
    {...props}
  >
    <rect x="0" y="3" rx="0" ry="0" width="125" height="12" />
  </LoadingPlaceholder>
);

export default ProductOptionListItemDescriptionPlaceholder;
