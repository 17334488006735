import { styled, css } from 'styled-components';

export const BenefitCardWrapper = styled.ul`
  background-color: ${props => props.theme.colors.lightBeige};
  padding: 15px 0;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 30px 0;
  }
`;

export const CardText = styled('li').withConfig({
  shouldForwardProp: props => !['parentIsLoading'].includes(props)
})<{ parentIsLoading?: boolean }>`
  font-weight: ${props => props.theme.fontWeights.fw2};
  padding-bottom: ${props => props.theme.spacerSizes.ss3};
  padding-left: ${props => props.theme.spacerSizes.ss3};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${props => props.parentIsLoading && css`
    visibility: hidden;
  `}

  &:last-child {
    padding-bottom: 0;
  }
`;

export const CardIcon = styled.span`
  width: 20px;
  margin-right: 8px;
  display: flex;
  justify-content: space-around;
`;
