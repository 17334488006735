import React from 'react';

import { Title } from '@arcade/component-library';

import {
  MainLogo
} from './logo.styles';

const Logo = ():React.ReactElement => {
  return (
    <MainLogo>
      <Title tag='h1' extraClass='h4' text='ACE' />
    </MainLogo>
  );
};

export default Logo;
