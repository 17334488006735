import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../lib/utils';
import { SortingGroup } from '@arcade/component-library';
import type { Campaign } from '../../pages/Dashboaard/dashboard';
import { sortingOptions } from './sorting';

import {
  TableWrapper,
  TableRow,
  FilterWrapper
} from './table.styles';

const Table = ({
  campaigns,
  updateCampaigns,
} : {
  campaigns: Campaign[],
  updateCampaigns: (_sort?: string) => Promise<void>
}): React.ReactElement => {
  const [sorting, setSorting] = useState('default');

  const onSortingChange = (id: string) => {
    setSorting(id);
  };

  const isRunning = (start:string, end?:string):boolean => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : null;
    const today = new Date();
    if (startDate.getTime() <= today.getTime() && ( !endDate || today.getTime() <= endDate.getTime())) return true;
    return false;
  };

  useEffect(() => {
    updateCampaigns(sorting);
  }, [sorting]);

  return (
    <>
      <FilterWrapper>
        <div>Search and Filters</div>
        <SortingGroup
          items={sortingOptions}
          selected={sorting}
          onChange={onSortingChange}
        />
      </FilterWrapper>
      <TableWrapper>
        <thead>
          <tr>
            <th>name</th>
            <th>Created by</th>
            <th>Last Edited By</th>
            <th>type</th>
            <th>status</th>
            <th>start Date</th>
            <th>end Date</th>
            <th>actions</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {campaigns.map(col => {
            const campaignLive = isRunning(col.startDate as string, col.endDate);
            const status = col.status === 'published' && campaignLive ? 'running' : col.status;
            return (
              <TableRow key={col.id} status={status}>
                <td>{col.name}</td>
                <td>{col.createdBy}</td>
                <td>{col.editedBy}</td>
                <td>{col.type}</td>
                <td>{status}</td>
                <td>{col.startDate ? formatDateTime(col.startDate) : 'n/a'}</td>
                <td>{col.endDate ? formatDateTime(col.endDate) : 'n/a'}</td>
                <td>{col?.actions?.length || 0}</td>
                <td><Link to={`/campaign/${col.id}`}>View</Link></td>
              </TableRow>
            );
          })}
        </tbody>
      </TableWrapper>
      <FilterWrapper>Pagination</FilterWrapper>
    </>
  );
};

export default Table;
