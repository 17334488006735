import React from 'react';
import { Icon } from '../Icon';

import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import type { Pagination as PaginationProps } from './pagination.types';

import {
  Wrapper,
  NavWrapper,
} from './pagination.styles';

/**
 *
 * @param {number} pages Number of pages.
 * @param {number} [pageSelected] The current page.
 * @param {boolean} [showAll] Show all items.
 * @param {(page:number | string) => void} onPageClick Callback on page click.
 * @param {boolean} [useShowAll = false] Whether to display the show all option
 */

export const Pagination = ({ pages, pageSelected, onPageClick, showAll, useShowAll = false, testid = 'pagination', id }: PaginationProps): React.ReactElement => {

  const giveEllipsis = (pageNumber: number) => {
    return (
      <li key={pageNumber}>
        <button disabled className='disabled'>...</button>
      </li>
    );
  };

  const giveNumberMarkup = (num: number, isSelected: boolean) => {
    return (
      <li key={`page-${num}`}>
        <button className={isSelected ? 'selected' : ''} onClick={() => onPageClick(num)} onKeyDown={e => executeOnKeyDown(e) && onPageClick(num)} tabIndex={0} data-testid={`${testid}-page-${num}`} aria-label={`Go to page ${num}`} aria-current={isSelected}>{num}</button>
      </li>
    );
  };

  const makeNumbersAndEllipsis = () => {
    const markup = [];
    if (pageSelected) {
      // Mimicks Amazon's pagination
      let needsEllipsis = true;
      for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
        const isSelected = pageNumber === pageSelected;
        if (pageNumber === 1 || pageNumber === pages) {
          // First and last pages
          markup.push(giveNumberMarkup(pageNumber, isSelected));
          continue;
        }
        if ((pageSelected === 1 && pageNumber === 3) || (pageSelected === pages && pages - 2 === pageNumber)) {
          // This is when end pages are selected, show an extra one
          markup.push(giveNumberMarkup(pageNumber, isSelected));
          continue;
        }
        if ((pageSelected === 4 && pageNumber === 2) || (pageSelected === pages - 3 && pageNumber === pages - 1)) {
          // This is to make sure there's a number when the ellipsis would only cover 1 number
          markup.push(giveNumberMarkup(pageNumber, isSelected));
          continue;
        }

        if (pageNumber - 1 === pageSelected || pageNumber === pageSelected || pageNumber + 1 === pageSelected) {
          // Any numbers next door to pageSelected are included
          markup.push(giveNumberMarkup(pageNumber, isSelected));
          // Middle numbers, so reset the needsEllipsis
          needsEllipsis = true;
          continue;
        }

        // Numbers are now covered, so ellipsis once
        if (needsEllipsis) {
          markup.push(giveEllipsis(pageNumber));
          needsEllipsis = false;
        }
      }
    } else {
      markup.push(giveNumberMarkup(1, false));
    }
    return markup;
  };

  return (
    <Wrapper>
      <NavWrapper role="navigation" aria-label={`${testid} navigation`} {...(id ? { id } : {})}>
        <ul>
          <li>
            <button
              className={pageSelected === 1 || pageSelected === 0 ? 'disabled-grey' : ''}
              onClick={() => onPageClick(pageSelected ? pageSelected - 1 : 0)}
              tabIndex={0}
              data-testid={`${testid}-prev`}
              aria-label="Previous page"
            >
              <Icon glyph='arrow_left' style={{ marginRight: '5px' }} /> Prev
            </button>
          </li>
          { makeNumbersAndEllipsis() }
          <li>
            <button
              className={pageSelected === pages || pageSelected === 0 ? 'disabled-grey' : ''}
              onClick={() => onPageClick(pageSelected ? pageSelected + 1 : 0)}
              onKeyDown={e => executeOnKeyDown(e) && onPageClick(pageSelected ? pageSelected + 1 : 0)}
              tabIndex={0}
              data-testid={`${testid}-next`}
              aria-label="Next page"
            >
              Next <Icon glyph='arrow_right' style={{ marginLeft: '5px' }} />
            </button>
          </li>
          { useShowAll &&
            <li>
              <button
                className={showAll ? 'selected' : ''}
                onClick={() => onPageClick('all')}
                onKeyDown={e => executeOnKeyDown(e) && onPageClick('all')}
                tabIndex={0}
                data-testid={`${testid}-showall`}
                aria-label="Show all"
                aria-current={showAll}
              >
                Show all
              </button>
            </li>
          }
        </ul>
      </NavWrapper>
    </Wrapper>
  );
};
