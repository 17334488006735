import React from 'react';

import type { PromoBanner as PromoBannerProps } from './promoBanner.types';

import {
  BannerWrapper
} from './promoBanner.styles';

/**
 * @param {string} [discountAmount] Discount amount to display - e.g. 10%.
 * @param {string} [text] Main text to display.
 * @param {string} [code] Promotional code to display.
 * @param {() => void} [onClick] Callback to handle click.
 * @param {string} [backgroundColour = '#1B1B1B'] Background colour of banner.
 * @param {string} [textColour = '#FFF'] Colour of text.
 */

export const PromoBanner = ({ discountAmount, text, code, testid = 'promo', onClick, backgroundColour = '#1B1B1B', textColour = '#FFF' }:PromoBannerProps):React.ReactElement => {
  const handleChange = (): void => {
    if (onClick) onClick();
  };

  const banner = code && text && discountAmount && <>{discountAmount} {text}<span> {code}</span></>;

  return (
    <BannerWrapper
      data-testid={testid}
      backgroundColour={backgroundColour}
      textColour={textColour}
    >
      {onClick ? (
        <button
          onClick={handleChange}
        >
          { banner }
        </button>
      ) : (
        banner
      )}
    </BannerWrapper>
  );
};
