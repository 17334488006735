import React from 'react';
import type { ResultCount as ResultCountProps } from './resultCount.types';

import {
  ResultCountWrapper,
  ResultCountText,
  ResultCountProgress,
  ResultCountBar
} from './resultCount.styles';

/**
 * Result count component
 *
 * @param {number} page The current page.
 * @param {number} totalResults The total number of items.
 * @param {number} itemsPerPage The amount of items per page.
 * @param {boolean} [showAll] Show all items.
 * @param {boolean} [loading=false] Inform the element been modified to assistive technologies.
 */
export const ResultCount = ({ page, totalResults, itemsPerPage, showAll, testid = 'counter', id, loading = false }: ResultCountProps): React.ReactElement => {
  const pages = Math.ceil(totalResults / itemsPerPage);
  const itemsShowing = (page >= pages || showAll) ? totalResults : (page * itemsPerPage);
  const precentLoaded = itemsShowing / (totalResults / 100);

  return (
    <ResultCountWrapper data-testid={testid} aria-live='polite' aria-busy={loading}>
      <ResultCountText id={`${id || 'progress'}-bar`}>
        Showing <b>{itemsShowing}</b> of <b>{totalResults}</b> items
      </ResultCountText>
      <ResultCountProgress aria-labelledby={`${id || 'progress'}-bar`} role='progressbar' aria-valuenow={Math.round(precentLoaded)}>
        <ResultCountBar percent={Math.round(precentLoaded)} />
      </ResultCountProgress>
    </ResultCountWrapper>
  );
};
