import React from 'react';

import { Title } from '../../atoms/Title';

import type { Card as CardProps } from './card.types';

import {
  CardWrapper,
  Description
} from './card.styles';

/**
 *
 * @param {string} title Card's title.
 * @param {React.ReactNode} [cardImage] Card's image/svg.
 * @param {string} description Card'd little blurb.
 * @param {React.ReactNode} [cardCta] Card call to action.
 *
 */

export const Card = ({ title, description, cardCta, cardImage }: CardProps): React.ReactElement => {
  return (
    <>
      <CardWrapper data-testid='card-wrapper'>
        {cardImage}
        <Title text={title} tag='h3' />
        <Description data-testid='description'>{description}</Description>
        {cardCta}
      </CardWrapper>
    </>
  );
};
