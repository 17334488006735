import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { ProductOptionListTitlePlaceholderWrapper } from './productOptionListPlaceholder.styles';

const ProductOptionListTitlePlacholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <ProductOptionListTitlePlaceholderWrapper>
    <LoadingPlaceholder
      id='productOptionListTitle'
      width={200}
      height={26}
      {...props}
    >
      <rect x="0" y="4" rx="0" ry="0" width="200" height="18" />
    </LoadingPlaceholder>
  </ProductOptionListTitlePlaceholderWrapper>
);

export default ProductOptionListTitlePlacholder;
