import React from 'react';
import type { StarRating as StarRatingProps } from './starRating.types';
import { RatingWrapper, Star } from './starRating.styles';

/**
 * Star rating.
 *
 * @param {number} rating Review store out of 5.
 * @param {React.ReactNode} [children] Option to add reviewer logo.
 */

export const StarRating = ({ rating, children, testid = 'star-rating' }:StarRatingProps ): React.ReactElement => {
  return (
    <RatingWrapper role="img" data-testid={testid} aria-label={`This product has a rating of ${rating} out of 5 stars`}>
      <Star aria-hidden="true" rating={rating} data-testid="stars"/>
      {children}
    </RatingWrapper>
  );
};
