import React from 'react';
import { Title } from '../../atoms/Title/index';
import type { YourDetails as YourDetailsProps } from './yourDetails.types';
import {
  YourDetailsWrapper,
  HeadingWrapper,
  Details,
  SectionDetail
} from './yourDetails.styles';

/**
 * Render user data.
 *
 * @param {string} title Main component title.
 * @param {{title: string, details: string[]}[]} parts YourDetails sections.
 *
 */

export const YourDetails = ({ title, parts, testid = 'yourdetails' }: YourDetailsProps): React.ReactElement => {
  return (
    <YourDetailsWrapper data-testid={testid}>
      <HeadingWrapper>
        <Title tag={'h3'} text={title}/>
      </HeadingWrapper>
      <Details>
        {parts.map(part => (
          <SectionDetail key={part.title} data-testid={`${testid}-part`}>
            <Title tag={'h4'} extraClass='h5' text={part.title} />
            {part.details.map(detail => (
              <p key={detail}>{detail}</p>
            ))}
          </SectionDetail>
        ))}
      </Details>
    </YourDetailsWrapper>
  );
};
