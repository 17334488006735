import React, { useState, useEffect, useRef } from 'react';
import { FormStep as FormStepProps } from './formStep.types';

import { Icon, Title } from '../../atoms';
import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import {
  FormStepWrapper,
  EditWrapper,
  HelpWrapper,
  FormStepTitle,
  HR,
  EditItem,
  TitleWrapper,
  ContentWrapper
} from './formStep.styles';

/**
 *
 * @param {string} title Step title.
 * @param {string} id Step id.
 * @param {import('./formStep.types').StepStates} [stepState='disabled'] State of the step.
 * @param {boolean} [editing=true] If the step is been edit.
 * @param {boolean} isPayment If is a payment/secure step.
 * @param {boolean} [isEditable=true] If the step is editable.
 * @param {() => void} onEdit Callback for the edit cta.
 * @param {() => void} onCancel Callback for the cancel cta.
 * @param {React.ReactNode} children Content of teh step.
 * @param {boolean} [parentIsLoading = false] Indicates is parent is loading data
 */

export const FormStep = ({ title, id, stepState = 'disabled', editing = true, children, onEdit, onCancel, isPayment, isEditable = true, testid = 'step', parentIsLoading = false }: FormStepProps): React.ReactElement => {
  const [stepContent, setStepContent] = useState<React.ReactNode>();
  const [wrapperHeight, setWrapperHeight] = useState<string>('auto');
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  const contentWrapper = useRef(null);

  const getElementHeight = (content:HTMLElement | null):number => {
    if (content) return content.offsetHeight;
    return 0;
  };

  useEffect(() => {
    if(!stepContent) {
      setStepContent(children);
    } else {
      setWrapperHeight(`${getElementHeight(contentWrapper.current)}px`);
      setIsTransitioning(true);
      setTimeout(() => setStepContent(children), 200);
      setTimeout(() => setWrapperHeight(`${getElementHeight(contentWrapper.current)}px`), 400);
      setTimeout(() => setIsTransitioning(false), 600);
      setTimeout(() => setWrapperHeight('auto'), 800);
    }
  }, [children]);

  const toggleEditCancel = ():void => {
    if(editing) {
      onCancel();
    } else {
      onEdit();
    }
  };

  if (parentIsLoading) {
    return (
      <FormStepWrapper stepState={stepState} isPayment={isPayment}>
        <TitleWrapper>
          <FormStepTitle>
            <Title parentIsLoading text='' tag='h3' />
          </FormStepTitle>
        </TitleWrapper>
        <HR />
      </FormStepWrapper>
    );
  }

  return (
    <FormStepWrapper stepState={stepState} isPayment={isPayment}>
      <TitleWrapper>
        <FormStepTitle data-testid={`${testid}-title`}>
          <Title text={title} tag='h3' id={`${id}-title`} />
          {isPayment && stepState === 'normal' ?
            <Icon fill={'black'} width={'15px'} glyph={'lock_solid'} testid={`${testid}-lock-icon`} /> : ''
          }
        </FormStepTitle>
        {isPayment && stepState === 'normal' ?
          <HelpWrapper><strong>Need help? </strong><span>Call us: +44(0)330 333 1113</span></HelpWrapper> : ''
        }
        {stepState === 'completed' && isEditable &&
          <EditWrapper
            aria-controls={`${id}-form-content`}
            onClick={() => toggleEditCancel()}
            onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && toggleEditCancel()}
            data-testid={`${testid}-toggle-item`}
          >
            <EditItem>
              {editing ? 'Cancel' : 'Edit'}
            </EditItem>
            <Icon glyph={editing ? 'close' : 'pencil_fill'} height='14' title={editing ? 'close fill' : 'pencil fill'} testid={editing ? `${testid}-close-icon` : `${testid}-edit-icon` } />
          </EditWrapper>
        }
      </TitleWrapper>
      <HR />
      {stepState === 'disabled' ? '' :
        <ContentWrapper
          contentHeight={wrapperHeight}
          transitioning={isTransitioning}
          aria-labelledby={`${id}-title`}
          id={`${id}-form-content`}
          data-testid={`${testid}-form-content`}
        >
          <div ref={contentWrapper}>{stepContent}</div>
        </ContentWrapper>}
    </FormStepWrapper>
  );
};
