import { styled } from 'styled-components';

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.layout.secondary};

  p {
    margin: 0;
    color: ${props => props.theme.layout.primary};
    font-weight: 800;
  }
`;

export const UserAvatar = styled.div`
  margin-right: 10px;
  display: inline-block;
  border-radius: 50%;
  color: ${props => props.theme.layout.primary};
  overflow: hidden;
  width: 50px;
  height: 50px;

  img {
    width: 100%;
  }
`;
