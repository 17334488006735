import React from 'react';

import type { Label as LabelProps } from './label.types';

import {
  StyledLabel
} from './label.styles';

/**
 * Simple `<label></label>` element
 *
 * @param {React.ReactNode} children Label or element to render as label.
 * @param {string} [labelFor] Element id to be linked to the <label> `for` attribute.
 */

export const Label = ({ children, labelFor }: LabelProps):React.ReactElement => <StyledLabel htmlFor={labelFor}>{children}</StyledLabel>;
