import React, { useState, useEffect } from 'react';

import { Radio } from '../Radio/radio';

import type { RadioGroup as RadioGroupProps } from './radioGroup.types';

import {
  RadioWrapper,
  RadioItem
} from './radioGroup.styles';

/**
 *
 * @param {string} [selected] Selected Option.
 * @param {string} name Name of the radio group.
 * @param {import('../Radio/radio.types').Radio[]} items Radio group options.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} [onChange] Callback option selection.
 *
 */

export const RadioGroup = ({ selected, name, items, testid = 'radio-button', onChange }:RadioGroupProps): React.ReactElement => {
  const [optionSelected, setOptionSelected] = useState<string | undefined>(selected);

  useEffect(() => {
    setOptionSelected(selected);
  }, [selected]);

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>):void => {
    const selectedValue = event.target.value;
    setOptionSelected(selectedValue);
    if(onChange) onChange(event);
  };

  return (
    <RadioWrapper>
      {items.map(item => {
        return (
          <RadioItem key={`${name}-${item.value}`}>
            <Radio
              testid={`${testid}-item`}
              name={name}
              checked={item.value === optionSelected}
              onChange={handleChange}
              {...item}
            />
          </RadioItem>
        );
      })}
    </RadioWrapper>
  );
};
