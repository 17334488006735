import { styled } from 'styled-components';

export const TitlePlaceholderWrapper = styled.span`
  svg {
    height: ${({ theme }) => theme.lineHeights.lh2};
    width: auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      height: ${({ theme }) => theme.lineHeights.lh3};
    }
  }
`;

export const SubtitlePlaceholderWrapper = styled.span`
  svg {
    height: ${({ theme }) => theme.lineHeights.lh0};
    width: auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      height: ${({ theme }) => theme.lineHeights.lh1};
    }
  }
`;
