import { styled } from 'styled-components';

import Flags from '../../../images/sprite-flags.png';
import Flags2 from '../../../images/sprite-flags@2x.png';

export const Flag = styled('div').withConfig({
  shouldForwardProp: props =>
    !['territory'].includes(props)
})<{ territory: string }>`
  width: 22px;
  height: 14px;
  display: block;
  margin-right: 5px;
  background-image: url(${Flags});
  overflow: hidden;

  @media (min-resolution: 192dpi) {
    background-image: url(${Flags2});
    background-size: 66px 28px;
  }

  ${props => props.territory === 'uk' && 'background-position: 0 0;'}
  ${props => props.territory === 'ie' && 'background-position: -44px 0;'}
  ${props => props.territory === 'us' && 'background-position: -22px 0;'}
  ${props => props.territory === 'row' && 'background-position: -44px -14px;'}
  ${props => props.territory === 'eu' && 'background-position: 0 -14px;'}
  ${props => props.territory === 'au' && 'background-position: -22px -14px;'}
`;
