import React from 'react';

import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import type { Tab as TabProps } from './tab.types';
import TabLargePlaceholder from './placeholders/TabLargePlaceholder';
import TabSmallPlaceholder from './placeholders/TabSmallPlaceholder';

import {
  TabButton
} from './tab.styles';

/**
 *
 * @param {(event:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void} onClick Tab callback.
 * @param {string} label tab's label.
 * @param {boolean} [selected=false] if the tab is selected.
 * @param {boolean} [responsive=false] if the tab will b eresponsive (100%).
 * @param {import('./tab.types').Sizes} [size=large] Size of the Tab
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const Tab = ({ id, onClick, label, selected = false, testid = 'tab_button', responsive = false, size = 'large', parentIsLoading = false, ...rest }:TabProps):React.ReactElement => {
  return (
    <TabButton
      id={id}
      onClick={onClick}
      onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && onClick(e)}
      selected={selected}
      responsive={responsive}
      size={size}
      data-testid={testid}
      {...rest}
    >{parentIsLoading ? (size === 'small' ? <TabSmallPlaceholder /> : <TabLargePlaceholder />) : label}</TabButton>
  );
};
