import React from 'react';

import { SuggestField } from '@arcade/component-library';

type PromoFieldType = {
  handleSelection: (_val:string) => void
  selectedPromos?: { id:string, text:string }[]
}

const PromoField = ({ handleSelection, selectedPromos = [] }:PromoFieldType):React.ReactElement => {
  const handleOnChange = (value:string) => {
    console.log(value);
  };

  return (
    <SuggestField
      placeholder='Start typing'
      results={[]}
      onChange={handleOnChange}
      handleResultClick={handleSelection}
      selected={selectedPromos}
    />
  );
};

export default PromoField;
