import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { ProductDescriptionPlaceholderWrapper } from './placeholders.styles';

export const ProductSubtitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productSubtitle'
    style={{ marginBottom: '30px' }}
    width={300}
    height={18}
    {...props}
  >
    <rect x="0" y="3" rx="0" ry="0" width="300" height="12" />
  </LoadingPlaceholder>
);

export const ProductDescriptionPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <ProductDescriptionPlaceholderWrapper>
    <LoadingPlaceholder
      id='productDescription'
      width={700}
      height={168}
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="700" height="16" />
      <rect x="0" y="28" rx="0" ry="0" width="700" height="16" />
      <rect x="0" y="56" rx="0" ry="0" width="700" height="16" />
      <rect x="0" y="84" rx="0" ry="0" width="700" height="16" />
      <rect x="0" y="112" rx="0" ry="0" width="700" height="16" />
      <rect x="0" y="140" rx="0" ry="0" width="170" height="16" />
    </LoadingPlaceholder>
  </ProductDescriptionPlaceholderWrapper>
);

export const PricingStringPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='pricingString'
    style={{ display: 'block', margin: '0 auto' }}
    width={280}
    height={18}
    {...props}
  >
    <rect x="0" y="3" rx="0" ry="0" width="280" height="12" />
  </LoadingPlaceholder>
);

export const ExtrasPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='extras'
    width={170}
    height={28}
    {...props}
  >
    <rect x="0" y="6" rx="0" ry="0" width="170" height="16" />
  </LoadingPlaceholder>
);

export const EndPricePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='endPrice'
    style={{ display: 'block', margin: '0 auto', marginTop: '20px' }}
    width={170}
    height={25}
    {...props}
  >
    <rect x="0" y="5" rx="0" ry="0" width="170" height="16" />
  </LoadingPlaceholder>
);
