import { styled } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  box-sizing: border-box;
`;

export const RightContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
