import { styled } from 'styled-components';

export const PageNavWrapper = styled.div``;

export const PageNav = styled.nav`
  background-color: ${props => props.theme.colors.white};

  ul {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const PageNavItem = styled.li`
  display: inline-block;
  padding: 15px 0;
  margin: 0 15px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    padding: 10px;
    text-decoration: none;

    &:visited {
      text-decoration: none;
      color: ${props => props.theme.colors.focus};
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;
