import { styled } from 'styled-components';

export const NavigationTileWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['imageUrl'].includes(props)
})<{ size?: string, imageUrl?: string }>`
  display: table;
  background-position: center;
  border-radius: ${props => props.theme.borderRadiuses.br2};
  background-color: ${props => props.theme.colors.grey};
  background-size: cover;
  background-image: url(${props => props.imageUrl});
  ${props =>
    props.size === 'small'
      ? `
   width: 100%;
   max-width: 160px;
   min-height: 104px;`
      : `
   width: 100%;
   max-width: 210px;
   min-height: 140px;
 `}
`;

export const NavigationText = styled('p').withConfig({
  shouldForwardProp: props =>
    !['textColor'].includes(props)
})<{ textColor: string }>`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  ${props =>
    props.textColor === 'dark'
      ? `
    color: ${props.theme.colors.black}`
      : `
    color: ${props.theme.colors.white}`}
`;
