import { styled } from 'styled-components';

export const CsWrapper = styled.div`
  padding: ${props => props.theme.spacerSizes.ss2};
`;

export const CtaWrapper = styled.div`
  padding: ${props => props.theme.spacerSizes.ss2} 0;
`;

export const CsBody = styled.div`
  padding: ${props => props.theme.spacerSizes.ss4} 0;
`;
