import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { TitlePlaceholderWrapper, ButtonTextPlaceholderWrapper } from './productRowPlaceholders.styles';

export const ProductRowTitlePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <TitlePlaceholderWrapper>
    <LoadingPlaceholder
      id='product-row-title'
      width={377}
      height={46}
    >
      <rect x="0" y="6" rx="0" ry="0" width="377" height="34" />
    </LoadingPlaceholder>
  </TitlePlaceholderWrapper>
);

export const ProductRowButtonTextPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <ButtonTextPlaceholderWrapper>
    <LoadingPlaceholder
      id='product-row-button-text'
      width={200}
      height={18}
    >
      <rect x="0" y="1" rx="0" ry="0" width="200" height="16" />
    </LoadingPlaceholder>
  </ButtonTextPlaceholderWrapper>
);
