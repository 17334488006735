import React from 'react';

import type { RichText as RichTextProps } from './richText.types';

import {
  RichTextWrapper
} from './richText.styles';

/**
 *
 * Renders Rich text element.
 * @param {React.ReactNode} children Element inside rich text.
 *
 */

export const RichText = ({ children }: RichTextProps): React.ReactElement => (
  <RichTextWrapper>
    {children}
  </RichTextWrapper>
);
