import { styled } from 'styled-components';

export const ConfirmationWrapper = styled.div`
  background-color: ${props => props.theme.colors.lightGrey};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    display: flex;
    flex-direction: row;
  }
`;

export const DescriptionWrapper = styled.div`
  .h2 {
    line-height: ${props => props.theme.lineHeights.lh5};
  }
`;

export const Description= styled.div`
  margin-top: 15px;
`;

export const ConfirmationButton = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 234px;
  justify-content: center;

  button {
    margin: 10px 0;
  }

  svg {
    margin-right: 8px;
  }
`;
