import { styled } from 'styled-components';

export const ImageUploadWrapper = styled.div`
  width: 100%;
  background-color: ${props => `${props.theme.colors.white}`};
  box-sizing: border-box;
`;

export const ImageWrapper = styled.div`
  display: flex;
  place-items: center center;
  flex-direction: row;
`;

export const ImageZone = styled.div`
  border: 1px solid ${props => `${props.theme.colors.lightGrey}`};
  background-color: ${props => props.theme.colors.lightBeige};
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 4px;
  margin-right: 20px;
`;

export const UploadError = styled.p`
  margin-top: 5px;
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  color: ${props => props.theme.colors.error};
  font-weight: ${props => props.theme.fontWeights.fw2};
`;
