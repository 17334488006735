import React from 'react';
import type { CategoryCarousel as CategoryCarouselType } from './categoryCarousel.types';
import { CategoryTile } from '../../atoms/CategoryTile';
import type { CategoryTile as CategoryTileType } from '../../atoms/CategoryTile/categoryTile.types';
import { Carousel } from '../../atoms/Carousel/carousel';

import {
  LayoutGroup,
} from './categoryCarousel.styles';

/**
 * @param {string} title Text heading at top of carousel
 * @param {import(../../atoms/CategoryTile/categoryTile.types).CategoryTile} categories Array of category tile data to generate category tiles in carousel
 */

export const CategoryCarousel = ({ title, categories, parentIsLoading, className = '', testid = 'browse-category' }: CategoryCarouselType): React.ReactElement => {
  const groupedCategories: CategoryTileType[][] = [];

  categories.forEach((category, i) => {
    const groupIndex = Math.floor(i / 3);
    if (!groupedCategories[groupIndex]) {
      groupedCategories[groupIndex] = [];
    }
    groupedCategories[groupIndex] = [...groupedCategories[groupIndex], category];
  });

  if (parentIsLoading) {
    return (
      <Carousel
        title=''
        className={className}
        testid={testid}
        parentIsLoading
      >
        {[[1,2,3],[4,5,6],[7,8,9],[10,11,12],[13,14,15]].map(item => (
          <LayoutGroup key={item[0]}>
            { item.map(inner => (
              <CategoryTile key={inner} title='' icon='horse_head' parentIsLoading testid={`${testid}-tile`} />
            )) }
          </LayoutGroup>
        ))}
      </Carousel>
    );
  }

  return (
    <Carousel
      title={title}
      className={className}
      testid={testid}
    >
      {groupedCategories.map(categoryGroup => (
        <LayoutGroup key={categoryGroup[0].title}>
          { categoryGroup.map(category => (
            <CategoryTile key={category.title + category.icon} {...category} testid={`${testid}-tile`} />
          )) }
        </LayoutGroup>
      ))}
    </Carousel>
  );
};
