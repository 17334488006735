import React from 'react';
import { Icon } from '../Icon';
import type { SpinningIcon as SpinningIconProps } from './spinningIcon.types';

import { SpinningIconWrapper } from './spinningIcon.styles';

/**
 * Spinning Icon or loading.
 *
 * @param {import('../../../icons').IconsType} [glyph=loader] Icon to be rotated.
 * @param {import('./spinningIcon.types').Size} [size=large] Size of the Icon.
 */
export const SpinningIcon = ({ glyph = 'loader', size = 'large', ...rest }: SpinningIconProps): React.ReactElement => {
  return (
    <SpinningIconWrapper size={size} data-testid="spinning-icon" >
      <Icon glyph={glyph} {...rest} />
    </SpinningIconWrapper>
  );
};
