import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const TabsTitleSmallPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='tabsTitleSmall'
    style={{ margin: '0 auto' }}
    width={180}
    height={34}
    {...props}
  >
    <rect x="0" y="5" rx="0" ry="0" width="180" height="24" />
  </LoadingPlaceholder>
);

export default TabsTitleSmallPlaceholder;
