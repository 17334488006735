import { styled } from 'styled-components';

export const RadioWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
`;

export const RadioItem = styled.div`
  padding-bottom: 25px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;
