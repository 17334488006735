import React from 'react';

import { userContext, User } from '../context/userContext';

const UserProvider = ({ children, user, token } : { children: React.ReactNode, user?: User, token: string }):React.ReactElement => {

  return (
    <userContext.Provider value={{ user: user, accessToken: token }}>
      {children}
    </userContext.Provider>
  );

};

export default UserProvider;
