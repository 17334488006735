import { styled } from 'styled-components';

export const MenuWrapper = styled.div`
  background: ${props => props.theme.colors.white};
`;

export const MenuItem = styled.a`
  text-transform: ${props => props.theme.fontDecor.fd4};
  text-decoration: none;
  display: block;
  width: 100%;
  line-height: ${props => props.theme.lineHeights.lh1};
  font-size: ${props => props.theme.fontSizes.fs1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.theme.colors.beige};
`;

export const SubMenuWrapper = styled.div`
  background-color: ${props => props.theme.colors.lightestGrey};

  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
    display: block;
    font-size: ${props => props.theme.fontSizes.fs2};
    line-height: ${props => props.theme.lineHeights.lh2};
  }
`;

export const SubMenuListItem = styled.li`
  > a {
    padding-bottom: 20px;
  }

  &:last-of-type > a {
    padding-bottom: 0;
  }
`;
