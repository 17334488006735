import { styled } from 'styled-components';

export const ResultLink = styled.a`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
`;

export const ImageWrapper = styled.div`
  display: inline-block;
  width: 65px;
  margin-right: 15px;

  @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
    width: 72px;
  }
`;

export const TextWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  h5 {
    font-size: ${({ theme }) => theme.fontSizes.fs2};
    line-height: ${({ theme }) => theme.lineHeights.lh2};
    font-weight: ${({ theme }) => theme.fontWeights.fw1};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.fs0};
    line-height: ${({ theme }) => theme.lineHeights.lh0};
  }
`;
