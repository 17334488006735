import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '../../atoms/Icon';
import { SearchBar as SearchBarType } from './searchBar.types';
import { SearchBarResult } from '../../atoms/SearchBarResult/searchBarResult';
import { getImage } from '../../../utils';

import {
  SearchBarFormWrapper,
  InputWrapper,
  SearchIconWrapper,
  TextInput,
  SearchBarControls,
  ClearButton,
  SearchSubmitButton,
  ResultsPane,
  Results,
  BottomPanel,
  ViewAllButton,
  PressEnterText,
} from './searchBar.styles';

export const SearchBar = ({ testid = 'search-bar', inputPlaceholderText, onChange, onSubmit, searchResults, totalResultCount, suggestionsOpen, setSuggestionsOpen }: SearchBarType):React.ReactElement => {
  const [inputVal, setInputVal] = useState<string>('');

  const parentRef = useRef<HTMLFormElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInputVal(inputValue);
    onChange(inputValue);
  };

  const submitInputVal = (inputVal: string) => {
    onSubmit(inputVal);
    setSuggestionsOpen(false);
    inputRef.current?.blur();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitInputVal(inputVal);
  };

  const handleClear = () => {
    setInputVal('');
    onChange('');
  };

  useEffect(() => {
    if (parentRef.current) {
      const focusOutEvent = (e: FocusEvent) => {
        if (!parentRef?.current?.contains((e.relatedTarget ?? null) as Node | null)) {
          // No focus inside component so close menu
          setSuggestionsOpen(false);
        }
      };
      const focusInEvent = (e: FocusEvent) => {
        if (inputRef.current === e.target) {
          setSuggestionsOpen(!!searchResults.length);
        }
      };
      parentRef.current.addEventListener('focusout', focusOutEvent);
      parentRef.current.addEventListener('focusin', focusInEvent);
      return () => {
        parentRef.current?.removeEventListener('focusout', focusOutEvent);
        parentRef.current?.removeEventListener('focusin', focusInEvent);
      };
    }
    return () => {};
  }, [parentRef, searchResults]);

  const ariaQuickResultsID = 'quick-results';

  return (
    <SearchBarFormWrapper ref={parentRef} onSubmit={handleSubmit} data-testid={testid}>
      <InputWrapper suggestionsOpen={suggestionsOpen}>
        <SearchIconWrapper>
          <Icon glyph='magnifying_glass' width='16' />
        </SearchIconWrapper>
        <TextInput
          ref={inputRef}
          type='text'
          value={inputVal}
          placeholder={inputPlaceholderText}
          suggestionsOpen={suggestionsOpen}
          onChange={handleChange}
          aria-label='Search magazines'
          title='Search magazines'
          aria-expanded={suggestionsOpen}
          aria-controls={ariaQuickResultsID}
        />
        <SearchBarControls>
          {!!inputVal.length && (
            <>
              <ClearButton type='button' onClick={handleClear} tabIndex={0}>
                <Icon glyph='close' />
              </ClearButton>
              <SearchSubmitButton type='button' onClick={() => submitInputVal(inputVal)} tabIndex={0}>
                <Icon glyph='arrow_right_line' />
              </SearchSubmitButton>
            </>
          )}
        </SearchBarControls>
      </InputWrapper>
      <ResultsPane suggestionsOpen={suggestionsOpen} searchResultsLength={searchResults.length}>
        <Results
          id={ariaQuickResultsID}
          aria-live='polite'
        >
          { searchResults.map(result => (
            <li
              key={result?.id ?? '' + result?.slug ?? '' + result?.title ?? '' + result?.images?.[0]?.path ?? ''}
            >
              <SearchBarResult
                title={result?.title ?? ''}
                subtitle={result?.subtitle ?? ''}
                image={getImage(result?.images, 'main')}
                searchTerm={inputVal}
                link={result?.slug ?? '#'} />
            </li>
          )) }
        </Results>
        <BottomPanel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SearchIconWrapper grey={true}>
              <Icon glyph='magnifying_glass' width='16' />
            </SearchIconWrapper>
            <ViewAllButton type='button' tabIndex={0} onClick={() => submitInputVal(inputVal)}>View all{ totalResultCount ? ` (${totalResultCount})` : '' } results for &apos;{inputVal}&apos;</ViewAllButton>
          </div>
          <PressEnterText>Press ENTER</PressEnterText>
        </BottomPanel>
      </ResultsPane>
    </SearchBarFormWrapper>
  );
};
