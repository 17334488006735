import { styled } from 'styled-components';
import { transparentize } from 'polished';

export const LinkWrapper = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
  border-radius: 10px;
  border: 1px ${({ theme }) => theme.colors.beige} solid;
  box-sizing: border-box;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.red)};

  svg {
    margin: 0 auto;
    width: 30px;
    height: auto;
    max-height: 30px;

    path {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const TextWrapper = styled.div`
  overflow: hidden;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.fw2};
  font-size: ${({ theme }) => theme.fontSizes.fs2};
  line-height: ${({ theme }) => theme.lineHeights.lh2};
  text-wrap: nowrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.fs3};
    line-height: ${({ theme }) => theme.lineHeights.lh3};
  }
`;

export const Subtitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.fw1};
  font-size: ${({ theme }) => theme.fontSizes.fs0};
  line-height: ${({ theme }) => theme.lineHeights.lh0};
  text-wrap: nowrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.fs1};
    line-height: ${({ theme }) => theme.lineHeights.lh1};
  }
`;
