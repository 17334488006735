import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const IncludedContentTitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='includedContentTitle'
    width={165}
    height={24}
    {...props}
  >
    <rect x="0" y="2" rx="0" ry="0" width="165" height="20" />
  </LoadingPlaceholder>
);

export default IncludedContentTitlePlaceholder;
