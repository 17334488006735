import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductOptionTabPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productOptionTab'
    width={120}
    height={70}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="60" height="20" />
    <rect x="0" y="25" rx="0" ry="0" width="100" height="14" />
    <rect x="0" y="54" rx="0" ry="0" width="120" height="14" />
  </LoadingPlaceholder>
);

export default ProductOptionTabPlaceholder;
