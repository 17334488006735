import React from 'react';
import { DialogBox as DialogBoxProps } from './dialogBox.types';

import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';

import { executeOnKeyDown } from '../../../utils';

import {
  DialogBoxWrapper,
  DialogBoxTitle,
  DialogBoxDescription,
  DialogBoxInformation,
  DialogBoxButtons,
  XButton,
  InfoIcon,
} from './dialogBox.styles';

/**
 *
 * @param {string} title Heading text for the dialog box - bold at the top
 * @param {string} description Description text for the dialog box - under the title
 * @param {string} infoMessage Info message text - the text in the grey info box
 * @param {boolean} [requiredAction = false] If true, will have confirm and decline buttons instead of close button
 * @param {() => void} [onClose = () => null] Callback for when close button clicked
 * @param {() => void} [onConfirm = () => null] Callback for when confirm button clicked
 * @param {() => void} [onDecline = () => null] Callback for when decline button clicked
 * @param {string} [closeButtonText = 'Close'] Text for the bottom (mobile) close button
 * @param {string} [confirmButtonText = 'Confirm'] Text for the confirm button
 * @param {string} [declineButtonText = 'Decline'] Text for the decline button
 *
 */

export const DialogBox = ({
  title,
  description,
  infoMessage,
  requiredAction = false,
  onClose = () => null,
  onConfirm = () => null,
  onDecline = () => null,
  closeButtonText = 'Close',
  confirmButtonText = 'Confirm',
  declineButtonText = 'Decline'
}: DialogBoxProps): React.ReactElement => {

  return (
    <DialogBoxWrapper>
      {!requiredAction && <XButton onClick={onClose} onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && onClose()}><Icon glyph='close' width='16' /></XButton>}
      {title && <DialogBoxTitle>{title}</DialogBoxTitle>}
      {description && <DialogBoxDescription hasTitle={!!title}>{description}</DialogBoxDescription>}
      {infoMessage && <DialogBoxInformation hasTitleOrDescription={!!title || !!description}><InfoIcon data-testid='dialog-close'><Icon glyph='info' height='12' /></InfoIcon><p>{infoMessage}</p></DialogBoxInformation>}
      {requiredAction ? (
        <DialogBoxButtons>
          <Button btnType='filled' size='large' responsive onClick={onConfirm} testid={'dialog-confirm'}>{confirmButtonText}</Button>
          <Button className='dialog-decline' btnType='outlined' size='large' responsive onClick={onDecline} testid={'dialog-decline'}>{declineButtonText}</Button>
        </DialogBoxButtons>
      ) : <DialogBoxButtons hasCloseButton={true}><Button btnType='outlined' size='large' responsive onClick={onClose} onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && onClose()} testid={'dialog-close'}>{closeButtonText}</Button></DialogBoxButtons> }
    </DialogBoxWrapper>
  );
};
