import { styled } from 'styled-components';

export const FilterDisplayWrapper = styled.div`
  display: flex;
`;
export const FilterButton = styled.div`
  button {
    margin: 10px 10px 0 0;
    padding: 12px 20px;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.beige};
    font-weight: 400;
    font-size: ${props => props.theme.fontSizes.fs1};
  }

  button:last-of-type {
    border: 0;
    text-decoration: underline;

    &:focus {
      border: 0;
      box-shadow: none;
    }
  }

  svg {
    margin-left: 10px;
    width: 12px;
    height: auto;
  }
`;
