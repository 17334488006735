import React from 'react';
import { Icon } from '../../atoms/Icon';
import type { Flash as FlashProps } from './flash.types';

import {
  FlashWrapper,
  CheckIcon,
  ErrorIcon,
  DescriptionWrapper,
} from './flash.styles';

/**
 *
 * @param {React.ReactNode} children Content of the prompt.
 * @param {import('./flash.types').LevelType} [level='error'] Type pf prompt.
 *
 */

export const Flash = ({ level='error', children }: FlashProps): React.ReactElement => (
  <FlashWrapper level={level} data-testid='flash-container'>
    {level === 'success' ?
      <CheckIcon level={level}><Icon glyph='check_line' width='22px' title='Check Fill' /></CheckIcon>
      :
      <ErrorIcon level={level}><Icon glyph='error_warning' width='22px' title='Error Warning' /></ErrorIcon>}
    <DescriptionWrapper>{children}</DescriptionWrapper>
  </FlashWrapper>
);
