import { styled } from 'styled-components';

export const CardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 15px;
  border: ${props => `1px solid ${props.theme.colors.grey}`};
  border-radius: ${props => `${props.theme.borderRadiuses.br2}`};
  background-color: ${props => `${props.theme.colors.white}`};
  color: ${props => `${props.theme.colors.black}`};
  line-height: ${props => props.theme.lineHeights.lh1};
  text-align: left;

  > svg,
  > img {
    width: 100%;
    margin-bottom: 10px;
  }

  > svg {
    color: ${props => `${props.theme.layout.secondary}`};
  }
`;

export const Description = styled.p`
  padding-bottom: 10px;
  line-height: ${props => props.theme.lineHeights.lh1};
`;
