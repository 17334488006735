import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const TabSmallPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='tabSmall'
    width={69}
    height={21}
    {...props}
  >
    <rect x="0" y="3" rx="0" ry="0" width="69" height="14" />
  </LoadingPlaceholder>
);

export default TabSmallPlaceholder;
