import React from 'react';

import type { CustomerOptions as CustomerOptionsType, OptionGroup } from './customerOptions.types';

import { DropDown } from '../../atoms/DropDown';

import {
  OptionsWrapper,
  OptionsGroup,
  SelectValue
} from './customerOptions.styles';

/**
 *
 * @param {import('./customerOptions.types').OptionGroup[]} optionGroups Customer option groups to be applied to the item.
 * @param {{ [key:string]: string }} [selected] The selected values for applied to the item.
 * @param {(_type:string, _code:string) => void} [onChange] OnChange event handler.
 * @param {boolean} [set=false] If the value has been set and cannot be changed.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading data
 *
 */

export const CustomerOptions = ({ optionGroups, selected, set = false, onChange, id, testid = 'option-groups', parentIsLoading = false }:CustomerOptionsType):React.ReactElement => {
  const getOptionContent = (group:OptionGroup):React.ReactElement | void => {
    if(group.type === 'select') {
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => onChange && onChange(group.code, event.target.value);
      return set ? selected && <SelectValue data-testid={`${testid}-${group.code}-value`}>{group.name} <span>{group.options.filter(opt => opt.value === selected[group.code])[0].label}</span></SelectValue> : <DropDown id={`${id}-${group.code}`} testid={`${testid}-${group.code}`} label={group.name} onChange={handleChange} options={group.options} selected={selected ? selected[group.code] : ''} />;
    }
  };

  if (parentIsLoading) {
    return (
      <OptionsWrapper>
        <OptionsGroup>
          <DropDown id={`${id}-loading`} testid={`${testid}-loading`} options={[]} parentIsLoading />
        </OptionsGroup>
      </OptionsWrapper>
    );
  }

  return (
    <OptionsWrapper data-testid={testid} {...(id ? { id } : {})}>
      {optionGroups.map(group => (
        <OptionsGroup key={group.code} data-testid={`${testid}-item`}>
          {getOptionContent(group) || <></>}
        </OptionsGroup>
      ))}
    </OptionsWrapper>
  );
};
