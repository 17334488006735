import React from 'react';

import type { BenefitCard as BenefitCardProps } from './benefitCard.types';

import { Icon } from '../Icon';

import {
  BenefitCardWrapper,
  CardText,
  CardIcon
} from './benefitCard.styles';

/**
 * List of benefits
 *
 * @param {{ icon: import('../../../icons').IconsType, label: string}[]} benfits List of benefits to add to the card.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading
 *
 */

export const BenefitCard = ({ benefits, testid = 'benefitCard', id, parentIsLoading = false }:BenefitCardProps):React.ReactElement => {
  return (
    <BenefitCardWrapper data-testid={testid} {...(id ? { id } : {})}>
      {benefits.map(benefit => (
        <CardText key={benefit.label} parentIsLoading={parentIsLoading}>
          <CardIcon><Icon glyph={benefit.icon} /></CardIcon><p>{benefit.label}</p></CardText>
      ))}
    </BenefitCardWrapper>
  );
};
