// Explicitly setting typography props
export const fontBasic = ( size?:string, lineHeight?:string ):string => `
  font-size: ${size || '1rem'};
  line-height: ${lineHeight || '1rem'};
`;

export const fontFeature = ( size?:string, letterSpacing?:string, lineHeight?:string ):string => `
  font-size: ${size || '1rem'};
  line-height: ${lineHeight || '1rem'};
  letter-spacing: ${letterSpacing || '0.125rem'};
`;

export const fontStyle = ( decorate?:string, transform?:string, weight?:string ):string => `
  text-decoration: ${decorate || 'none'};
  text-transform: ${transform || 'none'};
  font-weight: ${weight || 'normal'};
`;

/**
 * Mixin usage examples. See settings in defaultTheme.ts for values
 * ----------------------------------------------------------------
 * The purpose of these mixins is to provide a method of styling any
 * typographical element wihout it being needed to be written again.
 *
 * We start with ${fontBasic('','')} which takes 2 arguments, which
 * are size and lineHeight. These are two comma seperated strings.
 * Clamp('','','') is used for creating a range of sizes from
 * mobile to desktop that is also responsive.
 *
 * Use string interpolation for dynamic values if clamp() is used,
 * e.g., use backticks only when using css method below,
 * `clamp(${theme.fontSizes.fs4, 3vw, ${theme.fontSizes.fs6}` which is 24px - 34px.
 *
 * See fontSize and lineHeight values available in defaultTheme.ts
 * ---------------------------------------------------------------
 * See below example.
 *
 * ${fontBasic(
 *   `clamp(${theme.fontSizes.fs4}, 3vw, ${theme.fontSizes.fs6})`,    // size: argument 1,
 *   `clamp(${theme.lineHeights.lh4}, 3vw, ${theme.lineHeights.lh6})` // lineHeight: argument 2
 * )}
 * ---------------------------------------------------------------
 *
 * The next mixin we have to utilise is the ${fontFeature('','','')}
 * which takes 3 arguments: size, letterSpacing and lineHeight.
 *
 * Use string interpolation for dynamic values if clamp() is used,
 * e.g., use backticks only when using css method below,
 * `clamp(${theme.lineHeight.lh4, 3vw, ${theme.lineHeight.lh6}` which is 34px - 48px.
 *
 * See fontSize and lineHeight values available in defaultTheme.ts
 * ---------------------------------------------------------------
 * See example below
 *
 * ${fontFeature(
 *   `clamp(${theme.fontSizes.fs2}, 3vw, ${theme.fontSizes.fs4})`,    // size: argument 1,
 *   theme.spacerSizes.ss0,                                           // letterSpacing: argument 2,
 *   `clamp(${theme.lineHeights.lh2}, 3vw, ${theme.lineHeights.lh4})` // lineHeight: argument 3
 * )}
 * ---------------------------------------------------------------
 *
 * The last mixin for use is ${fontStyle('','','')} which takes 3
 * arguments: decorate, transform and weight. These are used when
 * the typography is requiring some subtle style changes from the
 * fontBasic or fontFeature mixins.
 *
 * Here we can transform to say uppercase, capitalise etc. Then
 * decorate with underline or line through. Also you can apply a
 * weight to the font from ultra thin (100) to ultra bold (900).
 *
 * See fontDecor and fontWeight values available in defaultTheme.ts
 * ----------------------------------------------------------------
 * See example below
 *
 * ${fontStyle(
 *   theme.fontDecor.fd2,  // decorate: argument 1,
 *   theme.fontDecor.fd4,  // transform: argument 2,
 *   theme.fontWeights.fw2 // weight: argument 3
 * )}
 *
 */
