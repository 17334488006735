import { createContext } from 'react';

export interface User {
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  name: string
  nickname: string
  picture: string
  sub: string
  updated_at: string
}

export interface UserContextType {
  user?: User
  accessToken?: string
}

const initialValue:UserContextType = {
  user: undefined,
  accessToken: undefined
};

export const userContext = createContext<UserContextType>(initialValue);
userContext.displayName = 'UserContext';
