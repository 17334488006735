import React from 'react';

import type { CartHeaderDisplay as CartHeaderDisplayProps } from './cartHeaderDisplay.types';

import { Icon } from '../Icon';
import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import {
  CartWrapper,
  IconWrapper,
  ItemNotification,
  BasketText
} from './cartHeaderDisplay.styles';

/**
 * Cart items quantity display
 *
 * @param {(event:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void} onClick Cart onClick callback.
 * @param {number} [itemsQty=0] Number of items in the cart.
 */

export const CartHeaderDisplay = ({ onClick, itemsQty = 0, testid = 'cartHeader', id }: CartHeaderDisplayProps):React.ReactElement => {

  return (
    <CartWrapper data-testid={testid} {...(id ? { id } : {})}>
      <button onClick={onClick} onKeyDown={e => executeOnKeyDown(e) && onClick(e)} tabIndex={0} data-testid={`button-${testid}`}>
        <IconWrapper>
          <Icon glyph="shopping_basket" height="18" title="Shopping Cart"/>
          {itemsQty > 0 && <ItemNotification>{itemsQty}<span className='visually-hidden'>items</span></ItemNotification>}
        </IconWrapper>
        <BasketText>Basket</BasketText>
      </button>
    </CartWrapper>
  );
};
