import { useContext } from 'react';
import PageWrapper from '../../components/PageWrapper/pageWrapper';

import { CodeBlock, Header } from './developers.styles';

import { userContext } from 'src/context/userContext';

import useUserRole from '../../hooks/useUserRole';

const Developers = ():React.ReactElement => {
  const userDetails = useContext(userContext);
  const { isDeveloper } = useUserRole();

  return (
    <PageWrapper title='Developers'>
      <Header>API Access Token</Header>
      {isDeveloper
        ? <CodeBlock>{userDetails.accessToken ?? 'Requesting access token...'}</CodeBlock>
        : <>You must have the developer role to retrieve an access token</>
      }
    </PageWrapper>
  );
};

export default Developers;
