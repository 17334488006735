import { styled } from 'styled-components';

export const Header = styled.div`
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
`;

export const CodeBlock = styled.div`
    padding: 20px;
    border: 1px solid ${props => props.theme.colors.darkGrey};
    word-break: break-all;
    background: ${props => props.theme.colors.lightGrey};
    border-radius: 5px;
    color: ${props => props.theme.colors.darkGrey}
`;
