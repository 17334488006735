import React from 'react';
import type { BenefitBanner as BenefitBannerProps } from './benefitBanner.types';
import { Icon } from '../Icon';
import Icons, { CompositeIcons } from '../../../icons';
import { BenefitIconPlaceholder, BenefitTextPlaceholder } from './placeholders/benefitBannerPlaceholders';
import {
  Banner,
  Benefit,
  IconWrap,
} from './benefitBanner.styles';

/**
 * @param {import('./benefitBanner.types').BenefitBanner['benefits']} benefits array of icons and labels to create benefits with
 */

export const BenefitBanner = ({ benefits, testid = 'benefitBanner', parentIsLoading, className = '' }: BenefitBannerProps):React.ReactElement => {
  if (parentIsLoading) {
    return (
      <Banner
        data-testid={testid}
        className={className}
      >
        {[1,2,3,4].map(item => (
          <Benefit key={item}>
            <IconWrap>
              <BenefitIconPlaceholder />
            </IconWrap>
            <div><BenefitTextPlaceholder /></div>
          </Benefit>
        ))}
      </Banner>
    );
  }

  return (
    <Banner
      data-testid={testid}
      className={className}
    >
      {benefits.map(benefit => (
        <Benefit key={benefit.label}>
          <IconWrap>
            {!!Icons[benefit.icon.toUpperCase()] ?
              <Icon glyph={benefit.icon} width='16px' />
              :
              CompositeIcons[benefit.icon.toUpperCase()]()
            }
          </IconWrap>
          <div>{benefit.label}</div>
        </Benefit>
      ))}
    </Banner>
  );
};
