import { styled, css } from 'styled-components';

import cardsFile from '../../../images/credit-card-icons-5.png';

const getCardPosition = (left:number, top:number, size:string):string => {
  const spacer = size === 'large' ? 4 : 3;
  const cardWidth = size === 'large' ? 51 : 38.25;
  const cardHeight = size === 'large' ? 32 : 24;
  const leftPosition = spacer + (left * (cardWidth + spacer * 2));
  const topPosition = spacer +(top * (cardHeight + spacer * 2));
  return `background-position: left -${leftPosition}px top -${topPosition}px;`;
};

export const Card = styled('div').withConfig({
  shouldForwardProp: props =>
    !['type', 'size'].includes(props)
})<{ type: string, size: string }>`
  background-image: url(${cardsFile});
  margin: 0 ${props => props.theme.spacerSizes.ss1};
  width: 51px;
  height: 32px;
  ${props => props.size === 'small' && css`
    background-size: 265.5px 180px;
    width: 38.25px;
    height: 24px;;
  `}
  ${props => props.type === 'visa' && getCardPosition(0, 3, props.size)}
  ${props => props.type === 'amex' && getCardPosition(0, 5, props.size)}
  ${props => props.type === 'mastercard' && getCardPosition(4, 0, props.size)}
  ${props => props.type === 'paypal' && getCardPosition(1, 1, props.size)}
  ${props => props.type === 'visa-electron' && getCardPosition(1, 0, props.size)}
  ${props => props.type === 'direct-debit' && getCardPosition(2, 5, props.size)}
  ${props => props.type === 'switch' && getCardPosition(2, 1, props.size)}
  ${props => props.type === 'solo' && getCardPosition(0, 4, props.size)}
`;
