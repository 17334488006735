import React from 'react';

import { Icon } from '../../components/atoms/Icon';

import { StarsWrapper } from './stars.styles';

export const Stars = ({
  numberOfStars = 5
}:{
  numberOfStars?: number
}): React.ReactElement => {
  const numArr = [];
  for (let i = 0; i < numberOfStars; i++) {
    numArr.push(i);
  }
  return (
    <StarsWrapper>
      {numArr.map(item => (
        <Icon key={item} glyph='star' width='8px' />
      ))}
    </StarsWrapper>
  );
};
