import { styled, css } from 'styled-components';

export const AccordionWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const AccordionItem = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.beige};

  &:last-child {
    margin-bottom: 0;
  }

  button {
    width: 100%;
    background-color: inherit;
    border: 0;
    position: relative;
    padding: ${props => props.theme.spacerSizes.ss2} 46px ${props => props.theme.spacerSizes.ss2} 0;
    font-size: ${props => props.theme.fontSizes.fs4};
    line-height: ${props => props.theme.lineHeights.lh2};
    font-weight: ${props => props.theme.fontWeights.fw1};
    cursor: pointer;
    text-align: left;

    @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
      padding: ${props => props.theme.spacerSizes.ss4} 46px ${props => props.theme.spacerSizes.ss4} 0;
    }
  }
`;

export const AccordionIcon = styled.span<{ open: boolean}>`
  position: absolute;
  display: block;
  padding: 10px;
  width: 16px;
  height: 16px;
  top: 3px;
  right: 0;
  border: 1px solid ${props => props.theme.layout.primary};
  border-radius: 50%;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    top: 13px;
  }

  svg {
    position: absolute;
    top: 10px;
    transition: transform 0.3s;
    font-size: ${props => props.theme.fontSizes.fs2};
    line-height: ${props => props.theme.fontSizes.fs2};
    ${props => props.open &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

export const AccordionContent = styled.div`
  padding-bottom: ${props => props.theme.spacerSizes.ss2};
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-bottom: ${props => props.theme.spacerSizes.ss4};
  }
`;
