import { styled, css } from 'styled-components';

export const DialogBoxWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 340px;
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    max-width: 569px;
  }
`;

export const DialogBoxTitle = styled.div`
  ${props => props.theme.headings.xLarge};
  font-weight: 600;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    text-align: left;
  }
`;

export const DialogBoxDescription = styled('div').withConfig({
  shouldForwardProp: props => !['hasTitle'].includes(props)
})<{ hasTitle: boolean }>`
  ${({ hasTitle }) => hasTitle && css`
    margin-top: 20px;
  `}
  ${props => props.theme.headings.body};
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    text-align: left;
  }
`;

export const DialogBoxInformation = styled('div').withConfig({
  shouldForwardProp: props => !['hasTitleOrDescription'].includes(props)
})<{ hasTitleOrDescription: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px;
  ${({ hasTitleOrDescription }) => hasTitleOrDescription && css`
    margin-top: 25px;
  `}
  background-color: ${props => props.theme.colors.lightestGrey};
  color: ${props => props.theme.colors.darkGrey};
  ${props => props.theme.headings.small};

  p {
    display: inline-block;
    box-sizing: border-box;
    width: calc(100% - 30px);
    padding-left: 10px;
  }
`;

export const InfoIcon = styled.div`
  align-self: flex-start;
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 16px;
  height: 16px;
  margin: 3px 5px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.darkGrey};

  svg {
    position: absolute;
    left: 6px;
    top: 1px;
    width: auto;
    height: 12px;
    fill: ${props => props.theme.colors.darkGrey};
  }
`;

export const DialogBoxButtons = styled('div').withConfig({
  shouldForwardProp: props => !['hasCloseButton'].includes(props)
})<{ hasCloseButton?: boolean }>`
  margin-top: 30px;
  ${({ hasCloseButton }) => hasCloseButton && css`
    @media (min-width: ${props => props.theme.breakpoints.laptop}) {
      display: none;
    }
  `}

  .dialog-decline {
    margin-top: 10px;
    margin-right: 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    height: 44px;

    .dialog-decline {
      margin-top: 0;
    }

    button {
      float: right;
      width: auto;
    }
  }
`;

export const XButton = styled.button`
  position: absolute;
  z-index: 5;
  width: 34px;
  height: 34px;
  right: 15px;
  top: 16px;
  padding: 0;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 4px ${props => props.theme.colors.lightGrey};

  svg {
    font-size: 24px;
  }

  display: none;

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    display: block;
  }
`;
