import { styled, css } from 'styled-components';

export const InlineWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    font-size: ${props => props.theme.fontSizes.fs2};
    color: ${props => props.theme.colors.error};
    top: 14px;
    right: 10px;
  }
`;

export const InlineInput = styled('input').withConfig({
  shouldForwardProp: props =>
    !['error'].includes(props)
})<{ error:boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: ${props => props.theme.fontSizes.fs2};
  border: 1px solid ${props => props.theme.colors.beige};
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.black};
  }

  ${props => props.error && css`
    border: 2px solid ${props.theme.colors.error};
  `}
`;

export const InlineButton = styled.button`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.beige};
  border-left: 0;
  border-radius: 0 4px 4px 0;
  font-size: ${props => props.theme.fontSizes.fs1};
  font-weight: ${props => props.theme.fontWeights.fw2};
  line-height: ${props => props.theme.lineHeights.lh1};
  padding: 12px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding-right: 5px;
  }
`;

export const CodeWrapper = styled.p`
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
  font-weight: ${props => props.theme.fontWeights.fw1};
  color: ${props => props.theme.colors.darkGrey};

  span,
  svg {
    color: ${props => props.theme.colors.black};
    padding-right: 5px;
  }
`;

export const RemoveButton = styled(InlineButton)`
  padding: 0;
  border: 0;
`;
