import { styled } from 'styled-components';

export const CartWrapper = styled.div`
  display: inline-block;

  &:focus {
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.colors.focus};
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.black : theme.colors.white};
    text-decoration: none;
    padding: 4px 10px;
    line-height: ${props => props.theme.lineHeights.lh0};
    font-size: ${props => props.theme.fontSizes.fs0};
    cursor: pointer;
    background: none;
    border: 0 none;

    svg {
      padding: 0;
      line-height: ${props => props.theme.lineHeights.lh2};
      font-size: ${props => props.theme.fontSizes.fs2};
    }

    @media (min-width: ${props => props.theme.breakpoints.laptop}) {
      flex-direction: row;
      padding: 10px;
      line-height: ${props => props.theme.lineHeights.lh2};
      font-size: ${props => props.theme.fontSizes.fs2};

      svg {
        padding-right: 6px;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.layout.primary : theme.colors.white};
      text-decoration: ${props => props.theme.fontDecor.fd6};

      svg {
        color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.black : theme.colors.white};
      }
    }
  }
`;

export const IconWrapper = styled.span`
  position: relative;
`;

export const ItemNotification = styled.span`
  position: absolute;
  top: -6px;
  right: -7px;
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: 10px;
  font-weight: ${props => props.theme.fontWeights.fw2};
  border: 1px solid ${props => props.theme.colors.white};
  color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.white : theme.layout.primary};
  background-color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.layout.primary : theme.colors.white};
  border-radius: 50%;
  padding: 2px 4px;

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    right: -1px;
    top: -4px;
  }
`;

export const BasketText = styled.span`
  font-size: ${props => props.theme.fontSizes.fs0};

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    font-size: ${props => props.theme.fontSizes.fs1};
  }
`;
