import React from 'react';

import { StickyFooter as StickyFooterProps } from './stickyFooter.types';

import {
  StickyFooterWrapper,
} from './stickyFooter.styles';

/**
 * Renders a sticky footer element.
 *
 * @param {React.ReactNode} children Element inside the sticky footer.
 * @param {boolean} [visible=false] Visibility of the footer.
 *
 */

export const StickyFooter = ({ children, visible = false, testid = 'sticky-footer' }: StickyFooterProps ): React.ReactElement => {

  return (
    <StickyFooterWrapper visible={visible} data-testid={testid}>
      {children}
    </StickyFooterWrapper>
  );
};
