import React from 'react';

import type { CountryFlag as CountryFlagProps } from './countryFlag.types';

import {
  Flag
} from './countryFlag.styles';

/**
 *
 * @param {import('./countryFlag.types').Codes} territory Flag territory.
 */

export const CountryFlag = ({ territory }:CountryFlagProps):React.ReactElement => (
  <Flag territory={territory} />
);
