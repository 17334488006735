import React, { useRef } from 'react';

import { components } from 'src/lib/api/openapi';

import { Image, Button, Icon } from '@arcade/component-library';

import {
  ImageUploadWrapper,
  ImageWrapper,
  ImageZone,
  UploadError
} from './fileUpload.styles';

type Asset = components['schemas']['Asset-ace_asset.read'];

interface ImageUploadProps {
  onUpload?:(_file:File) => void
  onRemove?: (_id:string) => void
  asset?: Asset
  fileType?: string
  uploadLabel?: string
  error?: string
}

const FileUpload = ({ onUpload, onRemove, asset, error, uploadLabel = 'Upload Image', fileType = 'image/*' }: ImageUploadProps):React.ReactElement => {

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = (e:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleUpload = async (event:React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target.files && event?.target.files[0];
    if (file && onUpload) onUpload(file);
  };

  const handleRemove = (e:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if( onRemove ) onRemove(asset?.id as string);
  };

  return (
    <ImageUploadWrapper>
      {asset?.path
        ? (
          <ImageWrapper>
            <ImageZone>
              <Image image={{ baseUrl: asset?.baseUrl as string, path: asset?.path as string, type: 'asset-img' }} imageWidth='100' imageHeight='100' title={asset?.id || ''} fit='contain' />
            </ImageZone>
            <Button size='compact' onClick={handleRemove}><Icon glyph='close'/>Remove</Button>
          </ImageWrapper>
        )
        : (
          <>
            <input type='file' name='file' style={{ display: 'none' }} ref={inputRef} onChange={handleUpload} accept={fileType} />
            <Button onClick={handleSelect} btnType='outlined' size='compact'><Icon glyph='arrow_up_bracket' title='upload icon' />{uploadLabel}</Button>
          </>
        )}
      {error && <UploadError>{error}</UploadError>}
    </ImageUploadWrapper>
  );
};

export default FileUpload;
