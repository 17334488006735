import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { ProductOptionListItemTitlePlaceholderWrapper } from './productOptionListItemPlaceholder.styles';

const ProductOptionListItemTitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <ProductOptionListItemTitlePlaceholderWrapper>
    <LoadingPlaceholder
      id='productOptionListItemTitle'
      width={200}
      height={28}
      {...props}
    >
      <rect x="0" y="4" rx="0" ry="0" width="200" height="20" />
    </LoadingPlaceholder>
  </ProductOptionListItemTitlePlaceholderWrapper>
);

export default ProductOptionListItemTitlePlaceholder;
