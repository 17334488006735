import React from 'react';
import { SearchBarResult as SearchBarResultType } from './searchBarResult.types';
import { Image } from '../Image';

import {
  ResultLink,
  ImageWrapper,
  TextWrapper
} from './searchBarResult.styles';

const boldenPartsOfStringThatMatch = (displayString: string, searchString: string) => {
  let boldenedString = displayString;
  searchString.split(' ').forEach(substr => {
    if (!substr.length) { return; }
    const indexOfString = displayString.toLowerCase().indexOf(substr.toLowerCase());
    if (indexOfString < 0) { return; }
    const originalCaseSearchString = displayString.slice(indexOfString, indexOfString + substr.length);
    boldenedString = boldenedString.split(originalCaseSearchString).join(`<b>${originalCaseSearchString}</b>`);
  });
  return boldenedString;
};

export const SearchBarResult = ({ testid = 'search-bar-result', title, subtitle, image, searchTerm, link }: SearchBarResultType):React.ReactElement => {
  return (
    <ResultLink data-testid={testid} href={link} tabIndex={0}>
      <ImageWrapper>
        <Image image={image} imageWidth='52' imageHeight='70' sizes='(min-width: 700px) 72px, 65px' srcSetWidths={[65, 72, 130, 144]} title='product image in search results' />
      </ImageWrapper>
      <TextWrapper>
        <h5 dangerouslySetInnerHTML={{ __html: searchTerm ? boldenPartsOfStringThatMatch(title, searchTerm) : title }} />
        { !!subtitle && <p dangerouslySetInnerHTML={{ __html: searchTerm ? boldenPartsOfStringThatMatch(subtitle, searchTerm) : subtitle }} /> }
      </TextWrapper>
    </ResultLink>
  );
};
