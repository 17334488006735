import React from 'react';

import { MenuAccordion } from '../MenuAccordion';

import type { MobileNavigation as MobileNavigationProps } from './mobileNavigation.types';

import {
  MenuWrapper,
  MenuItem,
  SubMenuWrapper,
  SubMenuListItem
} from './mobileNavigation.styles';

/**
 *
 * @param {import('./mobileNavigation.types').NavLink} items Navigation items.
 * @param {(e:string) => void} onClick Callback on item click.
 * @param {string} [testid = 'mobile-nav'] Test ID.
 *
 */

export const MobileNavigation = ({ items, chnl, onClick, testid = 'mobile-nav' }:MobileNavigationProps):React.ReactElement => {

  const handleClick = (e:React.MouseEvent, url?:string, target?:string | null) => {
    e.preventDefault();
    if( url) onClick(url, target);
  };

  return (
    <MenuWrapper data-testid={testid}>
      {items.map(item => {
        const hasSubMenu = item.items && item.items?.length > 0;
        const url = item.href ? (item.channelAware ? `/${chnl}${item.href}` : item.href) : '';
        if(!hasSubMenu) return (
          <MenuItem key={item.code} data-testid={`${testid}_item`} href={url} onClick={(event:React.MouseEvent<HTMLAnchorElement>) => handleClick(event, url, item.target)}>{item.name}</MenuItem>
        );
        return (
          <MenuAccordion key={item.code} label={item.name} testid={`${testid}_acc`} id={item.name.replaceAll(' ', '-')}>
            <SubMenuWrapper>
              <ul>
                {item.href && (
                  <SubMenuListItem key={`${item.code}-item`}>
                    <a href={url as string} onClick={(event:React.MouseEvent<HTMLAnchorElement>) => handleClick(event, url, item.target)}>
                      <b>{`View all ${item.name}`}</b>
                    </a>
                  </SubMenuListItem>
                )}
                {item.items?.map(subItem => {
                  const subUrl = subItem.channelAware ? `/${chnl}${subItem.href}` : subItem.href;
                  return (
                    <SubMenuListItem key={subItem.code}>
                      <a href={subUrl} onClick={(event:React.MouseEvent<HTMLAnchorElement>) => handleClick(event, subUrl, subItem.target)}>
                        {subItem.name}
                      </a>
                    </SubMenuListItem>
                  );
                })}
              </ul>
            </SubMenuWrapper>
          </MenuAccordion>
        );
      })}
    </MenuWrapper>
  );
};
