import { styled, css } from 'styled-components';
import type { Source } from './image.types';
import { getDimension } from './image';

export const PaddingDiv = styled('div').withConfig({
  shouldForwardProp: props => !['imageWidth', 'imageHeight', 'paddingValue', 'sources'].includes(props)
})<{ imageWidth: number, imageHeight: number, paddingValue?: string, sources?: Source[] }>`
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: ${({ imageWidth, imageHeight, paddingValue }) => paddingValue ? paddingValue : imageHeight / imageWidth * 100 + '%'};

  ${({ sources }) => sources?.length && sources.map(source => !!source.minWidth && !!source.imageHeight && !!source.imageWidth && css`
    @media (min-width: ${source.minWidth}) {
      padding-bottom: ${getDimension(source.imageHeight) / getDimension(source.imageWidth) * 100 + '%'};
    }
  `)}

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const PictureWrapper = styled('picture').withConfig({
  shouldForwardProp: props =>
    !['loaded'].includes(props)
})<{loaded: boolean}>`
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 400ms ease 0ms;
  ${props => props.loaded === true && css`
    opacity: 1;
  `}
`;

export const MainImage = styled('img').withConfig({
  shouldForwardProp: props =>
    !['objectFit', 'positionX', 'positionY'].includes(props)
})<{ objectFit: string, positionX: string, positionY: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  display: block;
  object-fit: ${props => props.objectFit};
  object-position: ${props => props.positionX} ${props => props.positionY};
`;
