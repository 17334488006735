import React, { useRef } from 'react';

import { Icon } from '../../atoms/Icon';

import type { InlineSubmission as InlineSubmissionProps } from './inlineSubmission.types';

import {
  InlineWrapper,
  InputWrapper,
  InlineInput,
  InlineButton,
  CodeWrapper,
  RemoveButton
} from './inlineSubmission.styles';

/**
 *
 * @param {string} [placeholder] Placeholder text on input field.
 * @param {string} [label='Apply'] Label for the submit button.
 * @param {(value:string) => void} onSubmit Callback on submit.
 * @param {(value:string) => void} onRemove Callback on remove.
 * @param {boolean} [error=false] Determine the error state of the input.
 * @param {string} [code] Value applied.
 * @param {string} [extra] Extra information about the value.
 * @returns
 */

export const InlineSubmission = ({ placeholder, label = 'Apply', code, extra, onSubmit, onRemove, testid = 'inline-sub', error = false }:InlineSubmissionProps):React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = ():void => {
    if (inputRef.current) {
      const value = inputRef.current.value;
      if (value !== '') {
        onSubmit(value);
      } else {
        inputRef.current.focus();
      }
    }
  };

  const handleRemove = ():void => {
    if(code) onRemove(code);
  };

  return (
    <InlineWrapper data-testid={testid}>
      {code
        ? (
          <>
            <CodeWrapper><Icon glyph='tag_solid' /><span>{code}</span>{extra}</CodeWrapper>
            <RemoveButton onClick={handleRemove} data-testid={`${testid}-remove`}><span>Remove</span><Icon glyph='close' /></RemoveButton>
          </>
        )
        : (
          <>
            <InputWrapper><InlineInput ref={inputRef} type='text' placeholder={placeholder} error={error} />{error && <Icon glyph='error_warning' />}</InputWrapper>
            <InlineButton onClick={handleSubmit} data-testid={`${testid}-submit`}><span>{label}</span><Icon glyph='arrow_right_line' /></InlineButton>
          </>
        )}
    </InlineWrapper>
  );
};
