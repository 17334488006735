import React, { useState, useEffect } from 'react';

import { Tab } from '../../atoms/Tab';

import type { Tabs as TabsProps } from './tabs.types';
import TabsTitleLargePlaceholder from './placeholders/TabsTitleLargePlaceholder';
import TabsTitleSmallPlaceholder from './placeholders/TabsTitleSmallPlaceholder';

import {
  TabsWrapper,
  TabsTitle,
  TabsList,
  TabWrapper
} from './tabs.styles';

/**
 *
 * @param {string} [title] Title of tabs.
 * @param {string} name Name to set accessibility selectors.
 * @param {number} [selected=0] Which tab is selected.
 * @param {string[]} tabTitles Titles of the tabs.
 * @param {JSX.Element[]} children Tabs contents.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const Tabs = ({ title, name, selected = 0, tabTitles, children, parentIsLoading = false }:TabsProps):React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState<number>(selected);
  const [mQuery, setMQuery] = useState<{ matches: boolean }>({ matches: true });

  useEffect(() => {
    if (typeof window !== undefined) {
      setMQuery({ matches: window.innerWidth > 700 });
      const mediaQuery = window.matchMedia('(min-width: 700px)');
      mediaQuery.addEventListener('change', setMQuery);

      return () => mediaQuery.removeEventListener('change', setMQuery);
    }
  }, []);

  return (
    <TabsWrapper>
      {title && <TabsTitle id={`${name}-tabs_title`}>{parentIsLoading ? ( <>{ mQuery.matches ? <TabsTitleLargePlaceholder /> : <TabsTitleSmallPlaceholder /> }</> ) : title}</TabsTitle>}
      <TabsList role="tablist" aria-labelledby={title ? `${name}-tabs_title` : ''}>
        {tabTitles.map((tabTitle, i) => (
          <TabWrapper key={tabTitle}>
            <Tab
              role="tab"
              aria-selected={i === selectedTab}
              aria-controls={`${name}-content${i}`}
              id={`${name}-tab${i}`}
              onClick={() => setSelectedTab(i)}
              selected={i === selectedTab}
              size={mQuery.matches ? 'large' : 'small'}
              responsive={!mQuery.matches}
              label={tabTitle}
              parentIsLoading={parentIsLoading}
            />
          </TabWrapper>
        ))}
      </TabsList>
      <div>
        {tabTitles.map((title, i) => (
          <div
            key={`content-${title}`}
            tabIndex={0}
            role="tabpanel"
            id={`${name}-content${i}`}
            aria-labelledby={`${name}-tab${i}`}
            hidden={i !== selectedTab}
            data-testid="tab-content"
          >
            {children[i] || <div/>}
          </div>
        ))}
      </div>
    </TabsWrapper>
  );
};
