import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductTileTitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productTileTitle'
    width={208}
    height={48}
    {...props}
  >
    <rect x="0" y="0"rx="0" ry="0" width="208" height="16" />
    <rect x="0" y="24"rx="0" ry="0" width="208" height="16" />
  </LoadingPlaceholder>
);

export default ProductTileTitlePlaceholder;
