import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Outlet } from 'react-router-dom';
import UserProvider from '../../lib/userProvider';
import { type User } from '../../context/userContext';
import OverlayProvider from '../../lib/overlayProvider';

import SideMenu from '../SideMenu/sideMenu';

import Login from '../Login/login';

import {
  MainContainer,
  RightContentWrapper
} from './app.styles';

const App = ()=> {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isAuthenticated && !token) {
      const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.AUTH0_AUDIENCE,
          }
        });
        setToken(accessToken);
      };
      getAccessToken();
    }
  }, [isAuthenticated]);

  if(!isAuthenticated || !token) {
    return <Login />;
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <UserProvider token={token} user={user as User}>
      <OverlayProvider>
        <MainContainer>
          <SideMenu/>
          <RightContentWrapper>
            <Outlet />
          </RightContentWrapper>
        </MainContainer>
      </OverlayProvider>
    </UserProvider>
  );
};

export default App;
