import React from 'react';
import type { ProductRow as ProductRowType } from './productRow.types';
import { ProductTile } from '../ProductTile/productTile';
import { ProductRowTitlePlaceholder, ProductRowButtonTextPlaceholder } from './placeholders/productRowPlaceholders';

import {
  Wrapper,
  Title,
  ScrollContainer,
  ProductContainer,
  ButtonStyled
} from './productRow.styles';

/**
 * @param {import('../ProductTile/productTile.types').ProductTile[]} products Array of products to show in productTile data format
 * @param {string} title Title to display at the top
 * @param {string} [buttonText = 'View all'] Text to display on the button at the bottom
 * @param {string} buttonOnClick Function to run when 'view all' button clicked
 * @param {number} [numberOfProductsToShow = 5] Maximum number of products to show
 * @param {number | undefined} numberOfItemsInCategory Total number of items in category (appears in brackets at the end of button text if present)
 */
export const ProductRow = ({ products, title, buttonText = 'View all', buttonOnClick, numberOfProductsToShow = 5, numberOfItemsInCategory, parentIsLoading, className = '', testid = 'product-row' }: ProductRowType): React.ReactElement => {
  const productsLoadingArray = [];
  for (let i = 0; i < numberOfProductsToShow; i++) {
    productsLoadingArray.push(i);
  }
  if (parentIsLoading) {
    return (
      <Wrapper className={className} data-testid={testid}>
        <Title><ProductRowTitlePlaceholder /></Title>
        <ScrollContainer>
          <ProductContainer>
            {productsLoadingArray.map(item => (
              <ProductTile className='product-tile' key={item} parentIsLoading onClick={() => {}} slug='' title='' subtitle='' id='' />
            ))}
          </ProductContainer>
        </ScrollContainer>
        <ButtonStyled btnType='outlined'>
          <ProductRowButtonTextPlaceholder />
        </ButtonStyled>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className} data-testid={testid}>
      <Title>{title}</Title>
      <ScrollContainer>
        <ProductContainer>
          {products.map((product, i) => i < numberOfProductsToShow && (
            <ProductTile className='product-tile' key={product.id} {...product} />
          ))}
        </ProductContainer>
      </ScrollContainer>
      <ButtonStyled btnType='outlined' onClick={buttonOnClick}>
        {buttonText + (numberOfItemsInCategory ? ` (${numberOfItemsInCategory})` : '')}
      </ButtonStyled>
    </Wrapper>
  );
};
