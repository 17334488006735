import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductTileSubtitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productTileSubtitle'
    width={208}
    height={21}
    {...props}
  >
    <rect x="0" y="0"rx="0" ry="0" width="208" height="16" />
  </LoadingPlaceholder>
);

export default ProductTileSubtitlePlaceholder;
