import { styled } from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.beige};
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 10px;
  outline: none;
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh2};
  font-weight: ${props => props.theme.fontWeights.fw1};
  color: ${props => props.theme.colors.black};
  box-sizing: border-box;
`;

export const InlineFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    flex-direction: row;
  }
`;
