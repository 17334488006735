import { styled } from 'styled-components';

export const StickyFooterWrapper = styled('footer').withConfig({
  shouldForwardProp: props =>
    !['visible'].includes(props)
})<{ visible: boolean }>`
  background-color: ${props => props.theme.colors.lightGrey};
  padding: 20px;
  bottom: 0;
  transform: ${props => (props.visible ? 'translateY(100%)' : 'translateY(0)')};
  transition: transform 500ms ease-in-out;
  position: sticky;
`;
