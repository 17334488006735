import { styled } from 'styled-components';

export const FormWrapper = styled.form`
  fieldset {
    border: 0;
  }
`;

export const FormFieldsWrapper = styled.div`
  padding: 20px;
  font-size: ${props => props.theme.fontSizes.fs1};
`;

export const FormLegend = styled.legend`
  padding: 20px 20px 0;
  font-size: ${props => props.theme.fontSizes.fs0};
  color: ${props => props.theme.colors.darkGrey};
  line-height: ${props => props.theme.lineHeights.lh0};
`;

export const FormActionWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightestGrey};
  padding: 20px;
`;

export const FormError = styled.p`
  color: ${props => props.theme.colors.red};
  text-align: center;
  padding-bottom: 20px;
`;
