import { Client } from '../../apiConfig';

export const AssetsApi = (client: Client) => ({
  addAsset: ({ formdata, token } : {formdata: FormData, token:string }) => {
    return client.POST('/api/ace/assets', {
      body: {
        file: ''
      },
      headers: {
        'Authorization': `Bearer ${token}`
      },
      bodySerializer: () => formdata
    });
  },
  getAssets: ({ token }:{ token:string}) => {
    return client.GET('/api/ace/assets', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
});
