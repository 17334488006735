import { styled } from 'styled-components';

export const YourDetailsWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const HeadingWrapper = styled.div`
  padding: ${props => props.theme.spacerSizes.ss4};
`;

export const Details = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
  padding: ${props => props.theme.spacerSizes.ss3};
`;

export const SectionDetail = styled.div`
  padding-bottom: ${props => props.theme.spacerSizes.ss3};
  color: ${props => props.theme.colors.darkGrey};

  &:last-child {
    padding-bottom: 0;
  }

  h4 {
    color: ${props => props.theme.colors.black};
    padding-bottom: ${props => props.theme.spacerSizes.ss2};
  }

  p {
    line-height: ${props => props.theme.lineHeights.lh1};
  }
`;
