import React from 'react';

import { DropDown, Label, ResultCount, Pagination } from '../../atoms';

import type { ProductPagination as ParoductPaginationProps } from './productPagination.types';

import {
  PaginationItems,
  ThirdPart,
  TwoThird,
  HalfPart,
  HR
} from './productPagination.styles';

/**
 * @param {React.ReactNode} children Items to be shown.
 * @param {(order:string) => void} onOrderChande When the order of items change.
 * @param {{ value:string, label:string }[]} orderOptions Populate the sorting dropdown.
 * @param {string} [defaultOrder] Pre-select the sorting.
 * @param {number | string} currentPage Selected page.
 * @param {number} itemsPerPage Number of items per page.
 * @param {number} totalItems Total of items.
 * @param {(page: number | string) => void} onPageClick Callback for page click.
 */

export const ProductPagination = ({ children, onOrderChange, orderOptions, defaultOrder, currentPage, totalItems, itemsPerPage, onPageClick, testid= 'product-pagination' }:ParoductPaginationProps):React.ReactElement => {
  const handleChange = (event:React.ChangeEvent<HTMLSelectElement>):void => onOrderChange(event.target.value);
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  const isShowAll = currentPage === 'all';

  return (
    <div data-testid={testid}>
      <PaginationItems>
        <ThirdPart>
          <ResultCount testid='counter-top' page={isShowAll ? 1 : currentPage as number} totalResults={totalItems} itemsPerPage={itemsPerPage} showAll={isShowAll}/>
        </ThirdPart>
        <ThirdPart>
          {totalItems > itemsPerPage && <Pagination pages={numberOfPages} {...(!isShowAll ? { pageSelected: isShowAll ? 1 : currentPage as number } : null)} showAll={isShowAll} onPageClick={onPageClick} />}
        </ThirdPart>
        <ThirdPart>
          <ThirdPart>
            <Label labelFor="productsPaginationSelect">Sort by:</Label>
          </ThirdPart>
          <TwoThird>
            <DropDown id="productsPaginationSelect" options={orderOptions} selected={defaultOrder} onChange={handleChange} testid={testid} />
          </TwoThird>
        </ThirdPart>
      </PaginationItems>
      <HR><hr/></HR>
      {children}
      <PaginationItems>
        <HalfPart>
          <ResultCount testid='counter-bottom' page={isShowAll ? 1 : currentPage as number} totalResults={totalItems} itemsPerPage={itemsPerPage} showAll={isShowAll}/>
        </HalfPart>
        <HalfPart>
          { totalItems > itemsPerPage && <Pagination pages={numberOfPages} {...(!isShowAll ? { pageSelected: isShowAll ? 1 : currentPage as number } : null)} showAll={isShowAll} onPageClick={onPageClick} testid="pagination-bottom" />}
        </HalfPart>
      </PaginationItems>
    </div>
  );
};
