import { styled, css } from 'styled-components';

const bottomPadding = 20;
const lh2InPixels = 24;

export const FilterContainer = styled.div`
  display: block;
  overflow: auto;
  max-height: ${lh2InPixels * 10.5 + bottomPadding * 10}px;
`;

export const FilterWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['isLastItem'].includes(props)
})<{ isLastItem: boolean }>`
  ${props => !props.isLastItem && css`
    padding-bottom: ${bottomPadding}px;
  `}
`;
