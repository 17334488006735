import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const IncludedContentContentPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='includedContent'
    width={340}
    height={112}
    {...props}
  >
    <rect x="0" y="6" rx="0" ry="0" width="340" height="16" />
    <rect x="0" y="34" rx="0" ry="0" width="340" height="16" />
    <rect x="0" y="62" rx="0" ry="0" width="340" height="16" />
    <rect x="0" y="90" rx="0" ry="0" width="100" height="16" />
  </LoadingPlaceholder>
);

export default IncludedContentContentPlaceholder;
