import { styled } from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  padding-bottom: 10px;
`;
