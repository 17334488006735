import React from 'react';

import { ProductOptionListItem } from '../ProductOptionListItem';
import ProductOptionListTitlePlacholder from './placeholders/productOptionListTitlePlaceholder';

import type { ProductOptionList as ProductOptionListProps } from './productOptionList.types';
import type { ProductOptionListItem as ProductOptionListItemType } from '../ProductOptionListItem/productOptionListItem.types';

import {
  SubscriptionTitle,
  OptionWrapper,
  ProductOptionListWrapper
} from './productOptionList.styles';

/**
 *
 * @param {import('../ProductOptionListItem/productOptionListItem.types')[]} variants Product Variants.
 * @param {string} [selected] SKU of selected variant.
 * @param {string} [title] Title above selected variant.
 * @param {(sku:string) => void} onSelect Callback on select.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is loading
 *
 */

export const ProductOptionList = ({ variants, selected, onSelect, title, testid = 'variants-display', parentIsLoading = false }:ProductOptionListProps):React.ReactElement => {
  const getSelectedOptionTitle = (variantsArr: ProductOptionListItemType[]):string => {
    const filteredVariant = variantsArr.filter(opt => opt.sku === selected)[0];
    return filteredVariant?.title ? filteredVariant.title : '';
  };

  return (
    <ProductOptionListWrapper data-testid={testid}>
      {parentIsLoading ? <ProductOptionListTitlePlacholder style={{ marginBottom: '5px' }} /> : (
        <>
          {title && variants && <SubscriptionTitle dangerouslySetInnerHTML={{ __html: `${title} <b>${getSelectedOptionTitle(variants)}</b>` }} />}
        </>
      )}
      {parentIsLoading ? [1,2,3].map(val => (
        <OptionWrapper key={val}>
          <ProductOptionListItem parentIsLoading={parentIsLoading} />
        </OptionWrapper>
      )) : (
        <>
          {variants?.map(item => (
            <OptionWrapper key={item.sku}>
              <ProductOptionListItem
                {...item}
                testid={`${testid}-opt`}
                onClick={onSelect ? () => onSelect(item?.sku as string) : undefined}
                selected={item.sku === selected}
              />
            </OptionWrapper>
          ))}
        </>
      )}
    </ProductOptionListWrapper>
  );
};
