import React from 'react';
import { NavigationTile as NavigationTileProps } from './navigationTile.types';

import {
  NavigationTileWrapper,
  NavigationText
} from './navigationTile.styles';

/**
 *
 * @param {string} title Title of the Tile;
 * @param {string} [imageUrl] url of the background image for teh Tile.
 * @param {import('./navigationTile.types').TextColor} [textColor='dark'] Color of the title.
 * @param {import('./navigationTile.types').ImageSize} [size='small'] Size of the Tile.
 */

export const NavigationTile = ({ title, imageUrl, textColor = 'dark', size = 'small', testid = 'tile', id }: NavigationTileProps): React.ReactElement => {
  return (
    <NavigationTileWrapper imageUrl={imageUrl} size={size} {...(id ? { id } : {})} >
      <NavigationText data-testid={`navigation-${testid}`} textColor={textColor} >
        {title}
      </NavigationText>
    </NavigationTileWrapper>
  );
};
