import { styled, keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinningIconWrapper = styled.div<{ size: string }>`
  animation: ${rotate} 800ms linear infinite;
  height: 100px;
  width: 100px;
  font-size: 100px;
  ${props => props.size === 'medium' && css`
    height: 50px;
    width: 50px;
    font-size: 50px;
  `}

  ${props => props.size === 'small' && css`
    height: 15px;
    width: 15px;
    font-size: 15px;
  `}
`;
