import { styled } from 'styled-components';

export const SubscriptionTitle = styled.p`
  ${props => props.theme.headings.medium}
  margin-bottom: 15px;
`;

export const OptionWrapper = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductOptionListWrapper = styled.div`
  margin-top: 16px;
`;
