import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { DescriptionPlaceholderWrapper } from './paymentCardPlaceholders.styles';

interface CardPlaceholderProps extends IContentLoaderProps {
  size: string
};

export const CardPlaceholder: React.FC<CardPlaceholderProps> = ({ size }): React.ReactElement => (
  <DescriptionPlaceholderWrapper size={size}>
    <LoadingPlaceholder
      id='card'
      width={51}
      height={32}
    >
      <rect x="0" y="0" rx="0" ry="0" width="51" height="32" />
    </LoadingPlaceholder>
  </DescriptionPlaceholderWrapper>
);
