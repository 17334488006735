import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductTilePricePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productTitlePrice'
    width={92}
    height={21}
    {...props}
  >
    <rect x="0" y="6"rx="0" ry="0" width="92" height="14" />
  </LoadingPlaceholder>
);

export default ProductTilePricePlaceholder;
