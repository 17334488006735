import { styled } from 'styled-components';

export const SingleIssueWrapperLink = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 30px;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  box-sizing: border-box;
`;

export const SingleIssueContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SingleIssueTitle = styled.div`
  padding: 20px 0;
  text-align: center;
  min-height: 64px;
  display: flex;
  align-items: center;

  h4 {
    font-size: ${props => props.theme.fontSizes.fs5};
    line-height: 32px;
    font-weight: ${props => props.theme.fontWeights.fw2};
  }
`;
