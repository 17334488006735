import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@arcade/component-library';

import Logo from '../Logo/logo';

import {
  LoginWrapper,
  LogoWrapper,
  LoginBox
} from './login.styles';

const Login = ():React.ReactElement => {
  const { loginWithRedirect } = useAuth0();
  return (
    <LoginWrapper>
      <LoginBox>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <p>Customisation Engine</p>
        <Button onClick={() => loginWithRedirect()}>Login</Button>
      </LoginBox>
    </LoginWrapper>
  );
};

export default Login;
