import React, { useState } from 'react';
import { components } from 'src/lib/api/openapi';
import { useFormContext } from 'react-hook-form';

import { apiClient } from 'src/lib/apiConfig';

import FileUpload from '../FileUpload/fileUpload';

type Asset = components['schemas']['Asset-ace_asset.read'];

interface ImageUploadFieldProps {
  description: string
  token: string
  name:string
  asset?: Asset
}

import {
  FieldWrapper,
  FieldDescription
} from './imageUploadField.styles';

const ImageUploadField = ({ description, token, name, asset, ...rest }:ImageUploadFieldProps):React.ReactElement => {
  const [uploadedImage, setUploadedImage] = useState<Asset | undefined>(asset);
  const [uploadError, setUploadError] = useState<string>();
  const { setValue } = useFormContext();

  const uploadImage = async (file:File):Promise<void> => {
    const formdata = new FormData();
    formdata.append('file', file, file.name);
    const { data, error } = await apiClient.assetsApi.addAsset({
      formdata,
      token: token
    });
    if (data) {
      setUploadedImage(data);
      setValue(name, data.id);
    }
    if(error) setUploadError(error.title);
  };

  const handleImageRemove = () => {
    setUploadedImage(undefined);
    setValue(name, null);
  };

  return (
    <FieldWrapper>
      <FieldDescription>{description}</FieldDescription>
      <input type='hidden' name={name} {...rest} />
      <FileUpload onUpload={uploadImage} onRemove={handleImageRemove} asset={uploadedImage} error={uploadError} />
    </FieldWrapper>
  );
};

export default ImageUploadField;
