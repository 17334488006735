import React from 'react';

import {
  LoaderWrap
} from './loader.styles';
import { SpinningIcon } from '@arcade/component-library';

const Loader = ():React.ReactElement => {
  return (
    <LoaderWrap>
      <SpinningIcon size="medium" />
    </LoaderWrap>
  );
};

export default Loader;
