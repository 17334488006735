import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { ProductListItemSubtitlePlaceholderWrapper } from './productListItemPlaceholder.styles';

const ProductListItemSubtitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <ProductListItemSubtitlePlaceholderWrapper>
    <LoadingPlaceholder
      id='productListItemSub'
      width={70}
      height={26}
      {...props}
    >
      <rect x="0" y="5" rx="0" ry="0" width="70" height="16" />
    </LoadingPlaceholder>
  </ProductListItemSubtitlePlaceholderWrapper>
);

export default ProductListItemSubtitlePlaceholder;
