import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { ProductOptionListItemSavingPlaceholderWrapper } from './productOptionListItemPlaceholder.styles';

const ProductOptionListItemSavingPlaceholder: React.FC<IContentLoaderProps> = ({ style, ...rest }): React.ReactElement => (
  <ProductOptionListItemSavingPlaceholderWrapper>
    <LoadingPlaceholder
      id='productOptionListItemSaving'
      style={{ ...style }}
      width={78}
      height={24}
      {...rest}
    >
      <rect x="0" y="1" rx="0" ry="0" width="78" height="22" />
    </LoadingPlaceholder>
  </ProductOptionListItemSavingPlaceholderWrapper>
);

export default ProductOptionListItemSavingPlaceholder;
