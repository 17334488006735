import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Icon } from '@arcade/component-library';

import Logo from '../Logo/logo';

import {
  SideMenuWrapper,
  MenuToggle,
  LogoWrapper,
  MenuText,
  MenuWrapper,
  MenuTitle,
  MenuList,
  MenuLink,
  LogOutLink
} from './sideMenu.styles';
import useUserRole from '../../hooks/useUserRole';

const SideMenu = ():React.ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { logout } = useAuth0();
  const { isDeveloper } = useUserRole();

  const handleLogout = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  const handleMenuToggle = (e:React.MouseEvent<HTMLButtonElement>):void => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <SideMenuWrapper expanded={expanded}>
      <MenuToggle onClick={handleMenuToggle} expanded={expanded}><Icon glyph='arrow_right' title={expanded ? 'Close menu' : 'Open menu'} /></MenuToggle>
      <div>
        <LogoWrapper expanded={expanded}>
          <Logo />
        </LogoWrapper>
        <MenuWrapper expanded={expanded}>
          <MenuTitle expanded={expanded}>{expanded ? 'MENU' : '---'}</MenuTitle>
          <MenuList>
            <MenuLink>
              <NavLink to='/' title='Dashboard'>
                <Icon glyph='book_open' title='Dashboard'/> <MenuText expanded={expanded}>Dashboard</MenuText>
              </NavLink>
            </MenuLink>
            <MenuLink>
              <NavLink to='campaign/new' title='New Campaign'>
                <Icon glyph='add' title='New Campaign'/> <MenuText expanded={expanded}>New Campaign</MenuText>
              </NavLink>
            </MenuLink>
            <MenuLink>
              <NavLink to='campaign/user/1234' title='My Campaigns'>
                <Icon glyph='star' title='My Campaigns'/> <MenuText expanded={expanded}>My Campaigns</MenuText>
              </NavLink>
            </MenuLink>
          </MenuList>
        </MenuWrapper>
        <MenuWrapper expanded={expanded}>
          <MenuTitle expanded={expanded}>{expanded ? 'OTHER' : '---'}</MenuTitle>
          <MenuList>
            <MenuLink>
              <NavLink to='profile' title='Profile'>
                <Icon glyph='account_solid' title='Profile'/> <MenuText expanded={expanded}>Profile</MenuText>
              </NavLink>
            </MenuLink>
            {
              isDeveloper &&
              <MenuLink>
                <NavLink to='developers' title='Developers'>
                  <Icon glyph='filters' title='Developers'/> <MenuText expanded={expanded}>Developers</MenuText>
                </NavLink>
              </MenuLink>
            }
          </MenuList>
        </MenuWrapper>
      </div>
      <LogOutLink expanded={expanded}>
        <a href='/' role='button' onClick={e => handleLogout(e)}>
          <Icon glyph='arrow_right_from_bracket'/> <MenuText expanded={expanded}>Logout</MenuText>
        </a>
      </LogOutLink>
    </SideMenuWrapper>
  );
};

export default SideMenu;
