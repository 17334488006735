import { styled, css } from 'styled-components';

export const DescriptionPlaceholderWrapper = styled('span').withConfig({
  shouldForwardProp: props => !['size'].includes(props)
})<{ size: string }>`
  svg {
    margin: 0 ${props => props.theme.spacerSizes.ss1};
    width: 51px;
    height: 32px;
    ${props => props.size === 'small' && css`
      width: 38.25px;
      height: 24px;;
    `}
  }
`;
