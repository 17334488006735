import React from 'react';

import type { Breadcrumbs as BreadcrumbsProps } from './breadcrumbs.types';

import {
  BreadcrumbsWrapper,
  Breadcrumb,
  BreadcrumbLink,
  LastBreadcrumb
} from './breadcrumbs.styles';

/**
 *
 * @param {import('./breadcrumbs.types').Breadcrumb[]} [breadcrumbs] Array of breadcrumb data
 * @param {(path: string) => void} [onLinkClick] Optional callback to use when wanting to alter link click behaviour
 *
 */

export const Breadcrumbs = ({ breadcrumbs, onLinkClick }: BreadcrumbsProps): React.ReactElement => {
  const handleClick = (e: React.MouseEvent, url: string) => {
    e.preventDefault();
    if (url.length) {
      if (onLinkClick) {
        onLinkClick(url);
      } else {
        if (typeof window !== undefined) {
          window.location.href = url;
        }
      }
    }
  };

  return (
    <BreadcrumbsWrapper>
      {breadcrumbs.map((breadcrumb, i) => (
        <Breadcrumb
          key={breadcrumb.text}
        >
          { i < breadcrumbs.length - 1 ? (
            <>
              <BreadcrumbLink href={breadcrumb?.link || ''} onClick={(ev:React.MouseEvent<HTMLAnchorElement>) => handleClick(ev, breadcrumb?.link || '')}>
                {breadcrumb.text}
              </BreadcrumbLink>
              <span>&gt;</span>
            </>
          ) : (
            <LastBreadcrumb
            >
              {breadcrumb.text}
            </LastBreadcrumb>
          )}
        </Breadcrumb>
      ))}
    </BreadcrumbsWrapper>
  );
};
