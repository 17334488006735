

import React from 'react';

import {
  CtaBanner as CtaBannerProps
} from './ctaBanner.types';

import {
  CtaWrapper,
  PreContent,
  Pricing,
  PromotionContent,
  InitialPrice,
  PreContentwrapper,
  PostContentWrapper
} from './ctaBanner.styles';

/**
 *
 * @param {string} preText The first seection of text.
 * @param {string} postText The second section of text.
 * @param {string} price The price of item.
 * @param {string} originalPrice The price of item before disocunt.
 * @param {string} saving The percentage saving of item.
 * @param {boolean} multiLine Display content on single or double line.
 * @param {React.ReactNode} children Extra items for the Cta Banner.
 *
 */

export const CtaBanner = ({ preText, price, originalPrice, saving, children, multiline = false, testid = 'cta-banner' }: CtaBannerProps): React.ReactElement => {
  return (
    <CtaWrapper multiline={multiline} data-testid={testid}>
      <PreContentwrapper>
        <PreContent>{preText}</PreContent>
        <Pricing>{price}</Pricing>
        <InitialPrice>{originalPrice}</InitialPrice>
        <PromotionContent data-testid={`${testid}-save`}>Save {saving}</PromotionContent>
      </PreContentwrapper>
      <PostContentWrapper>
        {children}
      </PostContentWrapper>
    </CtaWrapper>
  );
};
