import { styled } from 'styled-components';

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    margin-left: 5px;
  }
`;

export const Star = styled('div').withConfig({
  shouldForwardProp: props =>
    !['rating'].includes(props)
})<{rating: number}>`
  --star-color: ${props => props.theme.colors.white};
  --star-background: ${props => props.theme.colors.black};
  --percent: calc(${props => props.rating} / 5 * 100%);

  font-size: 25px;
  font-family: "Times New Roman", serif;
  line-height: 1;

  &::before {
    content: "★★★★★";
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${props => props.theme.colors.black};
  }
`;
