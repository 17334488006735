import React from 'react';

import type { PageNav as PageNavProps } from './pageNavigation.types';

import {
  PageNavWrapper,
  PageNav,
  PageNavItem
} from './pageNavigation.styles';

/**
 *
 * @param {{ id:string, label:string, link:string }[]} [items] Items to show in the navigation
 *
 */

export const PageNavigation = ({ items, id, testid = 'pagenav' }: PageNavProps): React.ReactElement => {

  return items ? (
    <PageNavWrapper {...(id ? { id } : {})}>
      <PageNav role="navigation" aria-label="Page Navigation" data-testid={`${testid}-content`}>
        <ul>
          {items?.map(item => {
            const { id, label, link } = item;
            return (
              <PageNavItem key={id} data-testid={`${testid}-links`}>
                <a href={link}>{label}</a>
              </PageNavItem>
            );
          })}
        </ul>
      </PageNav>
    </PageNavWrapper>
  ) : <div />;
};
