import { styled } from 'styled-components';

export const DashboardWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const PermissionError = styled.div`
  display: block;
  padding: 20px;
  margin: 50px auto 0;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.error};
  background-color: ${props => props.theme.colors.errorLight};
`;
