import React from 'react';

import { Title } from '../../atoms/Title';
import { Icon } from '../../atoms/Icon';
import { Button } from '../../atoms/Button';
import { Checkbox } from '../../atoms/Checkbox';

import { MenuAccordion } from '../MenuAccordion';

import type { MobileFilterGroup as MobileFilterGroupProps } from './mobileFilterGroup.types';

import {
  GroupWrapper,
  GroupHeader,
  CloseButton,
  GroupContent,
  GroupContentInner,
  FilterWrapper,
  GroupActions
} from './mobileFilterGroup.styles';

/**
 *
 * @param {string} [title] Title of menu e.g. 'Filters'
 * @param {MobileFilterGroupProps['filterGroups']} [filterGroups] Array of filter groups - the main content on the menu
 * @param {boolean} [open = false] Whether the entire menu is open or not
 * @param {() => void} [onClear] Callback used when 'Clear filters' is clicked
 * @param {() => void} [onClose] Callback used when X button is clicked
 * @param {(_filter:string, _selected:boolean) => void} [onSelect] Callback used when filter is selected
 * @param {string[]} [selectedFilters = []] Array of selected filter IDs
 * @param {string} [testid = 'mobile-filters'] Test ID
 *
 */

export const MobileFilterGroup = ({ title, filterGroups, open = false, onClear, onClose, onSelect, selectedFilters = [], testid = 'mobile-filters' }:MobileFilterGroupProps):React.ReactElement => {
  return (
    <GroupWrapper data-testid={`${testid}-group`} open={open}>
      <GroupHeader>
        <Title tag='h4' text={title} extraClass='h3' />
        <CloseButton aria-label='Close filters' onClick={() => onClose()} data-testid='filters-close'><Icon glyph='close' width='16' /></CloseButton>
      </GroupHeader>
      <GroupContent>
        <GroupContentInner>
          {filterGroups.map(group => (
            <MenuAccordion label={group.groupTitleLabel} key={group.groupTitleLabel} testid={testid} id={group.groupTitleID || group.groupTitleLabel.toLowerCase()}>
              {group.filters.map(filter => {
                const qtyLabel = filter?.qty ? `(${filter.qty})` : '';
                return (
                  <FilterWrapper key={filter.id}>
                    <Checkbox
                      label={`${filter.label} ${qtyLabel}`}
                      id={filter.id}
                      onChange={selected => onSelect(group.groupTitleID || group.groupTitleLabel.toLowerCase(), filter.id, selected)}
                      checked={selectedFilters.includes(filter.id)}
                      testid={`${testid}-checkbox`}
                      disabled={filter?.qty === 0}
                    />
                  </FilterWrapper>
                );
              })}
            </MenuAccordion>
          ))}
        </GroupContentInner>
      </GroupContent>
      <GroupActions>
        <Button btnType='filled' size='large' responsive onClick={() => onClose()} testid={'filters-view'}>View items</Button>
        <Button btnType='outlined' size='large' responsive onClick={() => onClear()} testid={'filters-clear'}>Clear filters</Button>
      </GroupActions>
    </GroupWrapper>
  );
};
