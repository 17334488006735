import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductTileFlagStringPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    id='productTitleFlagString'
    width={99}
    height={18}
    {...props}
  >
    <rect x="0" y="0"rx="0" ry="0" width="99" height="16" />
  </LoadingPlaceholder>
);

export default ProductTileFlagStringPlaceholder;
