import { styled } from 'styled-components';

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const PanelControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DropWrapper = styled.div`
  width: 50%;
`;

export const PanelPreview = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${props => props.theme.colors.black};
  display: block;
  box-sizing: border-box;
  padding: 5px;
`;

export const PanelIframe = styled.iframe`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;
