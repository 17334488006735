import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

export const SingleIssueTitlePlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <LoadingPlaceholder
    id='simgleIssueTitle'
    width={260}
    height={32}
  >
    <rect x="0" y="4" rx="0" ry="0" width="260" height="24" />
  </LoadingPlaceholder>
);
