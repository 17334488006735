import { styled } from 'styled-components';

export const BlockListWrapper = styled.div`
  text-align: center;

  .h1 {
    text-transform: ${props => props.theme.fontDecor.fd4};
    margin-bottom: calc(${props => props.theme.spacerSizes.ss5} - 1px);

    @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
      margin-bottom: calc(${props => props.theme.spacerSizes.ss5} - 2px);
    }
  }
`;

export const BlockListDiv = styled.div`
  padding-top: ${props => props.theme.spacerSizes.ss1};
  padding-bottom: ${props => props.theme.spacerSizes.ss1};

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-top: ${props => props.theme.spacerSizes.ss2};
    padding-bottom: ${props => props.theme.spacerSizes.ss3};
  }

  svg {
    color: ${props => props.theme.layout.primary};
    border: 1px solid ${props => props.theme.colors.black};
    border-radius: 50%;
    padding: ${props => props.theme.spacerSizes.ss2};
    width: 20px;
    height: 20px;

    @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
      padding: ${props => props.theme.spacerSizes.ss3};
      width: 40px;
      height: 40px;
    }
  }
`;

export const BlockListText = styled.p`
  text-transform: ${props => props.theme.fontDecor.fd4};
  letter-spacing: .1rem;
  margin-top: ${props => props.theme.spacerSizes.ss2};
  margin-bottom: ${props => props.theme.spacerSizes.ss2};
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh1};


  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
  font-size: ${props => props.theme.fontSizes.fs4};
  line-height: ${props => props.theme.lineHeights.lh2};
  }
`;
