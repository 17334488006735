import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const LeftColumn = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const FormWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 20px 10px 10px;
  overflow: scroll;
  border-right: 1px solid ${props => props.theme.colors.beige};
  margin: 20px 0 10px;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

export const PreviewWrapper = styled.div`
  margin: 20px 0 20px 20px;
  width: 60%;
`;
