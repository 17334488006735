import { styled } from 'styled-components';

export const ResultCountWrapper = styled.div`
  width: 100%;
  display: block;
  text-align: center;
`;

export const ResultCountText = styled.label`
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};

  b {
    font-weight: ${props => props.theme.fontWeights.fw2};
  }
`;

export const ResultCountProgress = styled.div`
  margin-top: 20px;
  width: 100%;
  position: relative;
  display: block;
  height: 4px;
  overflow: hidden;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.white};
`;

export const ResultCountBar = styled('div').withConfig({
  shouldForwardProp: props =>
    !['percent'].includes(props)
})<{ percent:number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: ${props => props.theme.colors.black};
  border-radius: 2px;
  width: ${props => props.percent}%;
  transition: width 0.5s;
`;
