import React from 'react';
import { ExtraContent as ExtraContentProps } from './extraContent.types';

import { Title, RichText } from '../../atoms';
import ExtraContentAsideListItemPlaceholder from './placeholders/extraContentAsideListItemPlaceholder';
import ExtraContentBodyPlaceholder from './placeholders/extraContentBodyPlaceholder';

import {
  ExtraContentWrapper,
  BodyWrapper,
  AsideContentWrapper,
  PreviewContent,
  AsideTitleWrapper
} from './extraContent.styles';

/**
 * Render extra content
 * @param {string} id Id of extra content
 * @param {string} title Extra content tilte
 * @param {string} body The body content as HTML
 * @param {string} asideBody The aside content as HTML
 * @param {string} asideTitle Title of aside content
 * @param {string} previewUrl Url of extra content link
 * @param {string} previewTitle Title of extra content link
 * @param {string} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const ExtraContent = ({ title, asideTitle, body, previewUrl, previewTitle, asideBody, testid = 'extra', parentIsLoading = false }: ExtraContentProps): React.ReactElement => {
  return (
    <ExtraContentWrapper>
      <BodyWrapper>
        <Title text={title} tag='h2' parentIsLoading={parentIsLoading} />
        <RichText>
          {parentIsLoading ?
            <div style={{ overflow: 'hidden' }}><ExtraContentBodyPlaceholder /></div>
            :
            <div data-testid={`${testid}-body`} dangerouslySetInnerHTML={{ __html: body }}/>
          }
        </RichText>
        {!parentIsLoading && previewUrl && previewTitle ? <PreviewContent href={previewUrl} role='button' rel='noreferrer' target='_blank' data-testid={`${testid}-url`}>{previewTitle}</PreviewContent> : null}
      </BodyWrapper>
      {parentIsLoading ? (
        <AsideContentWrapper>
          <AsideTitleWrapper><Title text='' tag='h3' parentIsLoading={parentIsLoading} /></AsideTitleWrapper>
          <RichText>
            <div style={{ margin: '19px 0 23px' }}>
              {[1,2,3,4].map(val => <ExtraContentAsideListItemPlaceholder key={val} /> )}
            </div>
          </RichText>
        </AsideContentWrapper>
      ):(
        <>
          {((asideTitle && asideTitle !== null) || (asideBody && asideBody !== null)) && <AsideContentWrapper>
            {asideTitle && <AsideTitleWrapper>
              <Title text={asideTitle} tag='h3'/>
            </AsideTitleWrapper>}
            {asideBody && <RichText>
              <section data-testid={`${testid}-asidecontent`} dangerouslySetInnerHTML={{ __html: asideBody }}/>
            </RichText>}
          </AsideContentWrapper>}
        </>
      )}

    </ExtraContentWrapper>
  );
};
