import React, { useRef, useState, useEffect } from 'react';
import type { Carousel as CarouselType } from './carousel.types';
import { Icon } from '../../atoms/Icon';
import { TitlePlaceholder } from './placeholders/carouselPlaceholders';

import {
  Wrapper,
  TopBar,
  Title,
  Arrows,
  ArrowCircle,
  ScrollContainer,
  PageDots,
  Dot,
} from './carousel.styles';

const DISABLED_CLASS = 'disabled';

const calculateMoveDistance = (scrollContainerWidth: number, itemWidth: number, gap: number, currentLeftScroll: number, forwards: boolean) => {
  const itemSpace = itemWidth + gap;
  const scrollRemainder = currentLeftScroll % itemSpace;
  const scrollPositionOfRightEdge = currentLeftScroll + scrollContainerWidth;
  const rightHandScrollRemainder = scrollPositionOfRightEdge % itemSpace;

  if (forwards) {
    // Reverse with rightHandScrollRemainder to move the cut-off right-hand element outside the right edge, and move forward entire container width to make snug on left
    return -rightHandScrollRemainder + scrollContainerWidth;
  } else {
    // Reverse with scrollRemainder to contain the left cut-off element, and move it to be snug to right by removing the space between it and the right-hand side
    return -scrollRemainder - (scrollContainerWidth - itemWidth);
  }

};

const createDots = (amountOfDots: number, onDotClick: (_index: number) => void) => {
  const elementArr = [];
  for (let i = 0; i < amountOfDots; i++) {
    elementArr.push(
      <Dot onClick={() => onDotClick(i)} />
    );
  }
  return elementArr;
};

const giveElementValues = (element: HTMLDivElement) => {
  return { scrollLeft: element.scrollLeft, scrollWidth: element.scrollWidth, clientWidth: element.clientWidth };
};

const givePageWidth = (scrollWidth: number, scrollableContainerWidth: number) => {
  const numOfPages = Math.ceil(scrollWidth / scrollableContainerWidth);
  return scrollWidth / numOfPages;
};

/**
 * @param {string} [title] Text heading at top of carousel
 * @param {React.ReactNode | JSX.Element} [children] Carousel items - make sure they don't have a container and each item is the same width
 * @param {number} [itemGap = 20] Gap between carousel items (horizontal)
 */

export const Carousel = ({ title, children, itemGap = 20, parentIsLoading, className = '', testid = 'carousel' }: CarouselType): React.ReactElement => {
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  const leftArrowRef = useRef<HTMLButtonElement | null>(null);
  const rightArrowRef = useRef<HTMLButtonElement | null>(null);
  const [itemWidth, setItemWidth] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const handleDotClick = (dotIndex: number) => {
    const scrollableContainer = scrollableContainerRef.current;
    if (scrollableContainer) {
      const { clientWidth, scrollWidth } = giveElementValues(scrollableContainer);
      const pageWidth = givePageWidth(scrollWidth, clientWidth);
      scrollableContainer.scrollTo({
        left: dotIndex * pageWidth,
        behavior: 'smooth'
      });
    }
  };

  const handleArrowClick = (forwards: boolean) => {
    const scrollContainer = scrollableContainerRef.current;
    if (scrollContainer) {
      const scrollLeft = scrollContainer.scrollLeft;
      scrollContainer.scrollTo({
        left: scrollLeft + calculateMoveDistance(scrollContainer.clientWidth, itemWidth, itemGap, scrollLeft, forwards),
        behavior: 'smooth'
      });
    }
  };

  // Handle disabling/ un-disabling arrows at the edges of the scroll container
  const updateArrowState = (scrollLeft: number, scrollWidth: number, clientWidth: number) => {
    const leftArrow = leftArrowRef.current;
    const rightArrow = rightArrowRef.current;
    if (leftArrow && rightArrow) {
      if (scrollLeft === 0) {
        leftArrow.classList.add(DISABLED_CLASS);
      } else {
        leftArrow.classList.remove(DISABLED_CLASS);
      }
      if (scrollLeft === (scrollWidth - clientWidth)) {
        rightArrow.classList.add(DISABLED_CLASS);
      } else {
        rightArrow.classList.remove(DISABLED_CLASS);
      }
    }
  };

  // Updates the active page which highlights the active page dot
  const updateActivePage = (scrollLeft: number, scrollableContainerWidth: number, scrollWidth: number) => {
    const pageWidth = givePageWidth(scrollWidth, scrollableContainerWidth);
    setActivePage(Math.round(scrollLeft / pageWidth));
  };

  // Updates total pages which gives number of page dots
  const updateTotalPages = (scrollableContainerWidth: number, scrollWidth: number) => setTotalPages(Math.ceil(scrollWidth / scrollableContainerWidth));

  // Record the width of a carousel item
  const updateScrollingItemWidth = (scrollableContainer: HTMLDivElement) => {
    const scrollingItemWidth = scrollableContainer.children[0]?.clientWidth;
    if (scrollingItemWidth) setItemWidth(scrollingItemWidth);
  };

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef.current;
    if (scrollableContainer) {
      // Grab display values we need on load, and update them on resize
      const updateAll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = giveElementValues(scrollableContainer);
        updateScrollingItemWidth(scrollableContainer);
        updateTotalPages(clientWidth, scrollWidth);
        updateActivePage(scrollLeft, clientWidth, scrollWidth);
        updateArrowState(scrollLeft, scrollWidth, clientWidth);
      };
      updateAll();
      window.addEventListener('resize', updateAll, { passive: true });

      // Display values that update on scroll
      const updateScrollDependantValues = () => {
        const { scrollLeft, scrollWidth, clientWidth } = giveElementValues(scrollableContainer);
        updateActivePage(scrollLeft, clientWidth, scrollWidth);
        updateArrowState(scrollLeft, scrollWidth, clientWidth);
      };
      scrollableContainer.addEventListener('scroll', updateScrollDependantValues, { passive: true });

      return () => {
        window.removeEventListener('resize', updateAll);
        scrollableContainer.removeEventListener('scroll', updateScrollDependantValues);
      };
    }
    return () => {};
  }, []);

  if (parentIsLoading) {
    return (
      <Wrapper className={className} data-testid={testid}>
        <TopBar>
          <Title><TitlePlaceholder /></Title>
          <Arrows>
            <ArrowCircle className={DISABLED_CLASS} ref={leftArrowRef} tabIndex={0} aria-label='carousel backwards'><Icon glyph='arrow_left' /></ArrowCircle>
            <ArrowCircle className={DISABLED_CLASS} ref={rightArrowRef} tabIndex={0} aria-label='carousel forwards'><Icon glyph='arrow_right' /></ArrowCircle>
          </Arrows>
        </TopBar>
        <ScrollContainer
          ref={scrollableContainerRef}
          flexGap={itemGap}
        >
          {children}
        </ScrollContainer>
        <PageDots activePage={activePage}>
          {createDots(totalPages, handleDotClick)}
        </PageDots>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className} data-testid={testid}>
      <TopBar>
        <Title>{title}</Title>
        <Arrows>
          <ArrowCircle ref={leftArrowRef} onClick={() => handleArrowClick(false)} tabIndex={0} aria-label='carousel backwards'><Icon glyph='arrow_left' /></ArrowCircle>
          <ArrowCircle ref={rightArrowRef} onClick={() => handleArrowClick(true)} tabIndex={0} aria-label='carousel forwards'><Icon glyph='arrow_right' /></ArrowCircle>
        </Arrows>
      </TopBar>
      <ScrollContainer
        ref={scrollableContainerRef}
        flexGap={itemGap}
      >
        {children}
      </ScrollContainer>
      <PageDots activePage={activePage}>
        {createDots(totalPages, handleDotClick)}
      </PageDots>
    </Wrapper>
  );
};
