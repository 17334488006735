import { styled, css } from 'styled-components';

import type { StepStates } from './formStep.types';

export const FormStepWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['isPayment', 'stepState'].includes(props)
})<{ isPayment: boolean, stepState: StepStates }>`
  box-shadow: 0 0 4px 0 ${props => props.theme.colors.lightGrey};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 20px;
  ${props => props.isPayment && css`
    border: 1px solid ${props => props.theme.colors.black};

    ${HR} {
      border-top: 1px solid ${props => props.theme.colors.black};
    }
  `}

  ${props => props.stepState === 'completed' && css`
    border: 1px solid ${props => props.theme.colors.lightGrey};

    ${HR} {
      border-top: 1px solid ${props => props.theme.colors.lightGrey};
    }
  `}

  ${props => props.stepState === 'disabled' && css`
    border: 1px solid ${props => props.theme.colors.lightGrey};

    ${FormStepTitle} {
      color: ${props => props.theme.layout.secondary};
    }

    ${HR} {
      border-top: 1px solid ${props => props.theme.colors.lightGrey};
    }

    ${EditWrapper} {
      display: none;
    }
  `}
`;

export const FormStepTitle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  > svg {
    margin-left: ${props => props.theme.spacerSizes.ss2};
    margin-top: -3px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.spacerSizes.ss4};
  justify-content: space-between;
`;

export const EditWrapper= styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;

  svg {
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    box-shadow: 0 0 4px 0 ${props => props.theme.colors.lightGrey};
    border-radius: 50px;
  }
`;

export const HelpWrapper = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    display: block;
  }
`;

export const HR = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const EditItem = styled.div`
  ${props => props.theme.headings.smallBold}
`;

export const ContentWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['contentHeight', 'transitioning'].includes(props)
})<{ contentHeight: string, transitioning: boolean }>`
  height: ${props => props.contentHeight};
  opacity: ${props => props.transitioning ? 0 : 1};
  transition: all 0.2s ease-in-out;
`;
