import { styled } from 'styled-components';
import { Button } from '../Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const ShowingItemsText = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.lh2};
  font-size: ${({ theme }) => theme.fontSizes.fs2};
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.fw2};
`;

export const ProgressBarOuter = styled.div`
  width: clamp(260px, 69.33vw, 800px);
  height: 4px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  line-height: 0;
`;

export const ProgressBarInner = styled('div').withConfig({
  shouldForwardProp: props => !['progress'].includes(props)
})<{ progress: number }>`
  width: ${({ progress }) => progress > 100 ? 100 + '%': progress + '%'};
  height: 4px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.grey};
`;

export const LoadMoreButton = styled(Button)`
  width: clamp(200px, 53.33vw, 365px);
  color: ${({ theme }) => theme.colors.black};
`;
