import { styled, css } from 'styled-components';

export const NumberPickerWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['parentIsLoading'].includes(props)
})<{ parentIsLoading?: boolean }>`
  display: flex;

  ${props => !!props.parentIsLoading && css`
    > button {
      cursor: default;
    }
  `}
`;

const AlterNumber = styled('button').withConfig({
  shouldForwardProp: props => !['parentIsLoading'].includes(props)
})<{ parentIsLoading?: boolean }>`
  cursor: pointer;
  padding: 0 16px;
  background: none;

  @media (min-width: ${props =>props.theme.breakpoints.laptop}) {
    padding: 0 12px;
  }

  ${props => !!props.parentIsLoading && css`
    svg {
      color: ${props.theme.colors.darkGrey};
    }
  `}
`;

export const DecrementNumber = styled(AlterNumber)`
  border: 0;
  border-top: 1px solid ${props => props.theme.colors.beige};
  border-bottom: 1px solid ${props => props.theme.colors.beige};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid ${props => props.theme.colors.beige};
`;

export const IncrementNumber = styled(AlterNumber)`
  border: 1px solid ${props => props.theme.colors.beige};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0;
`;

export const Quantity = styled('div').withConfig({
  shouldForwardProp: props =>
    !['hideControls'].includes(props)
})<{hideControls: boolean}>`
  border: 1px solid ${props => props.theme.colors.beige};
  padding: 10px 18px;
  ${props => props.hideControls === true
    ?`
    border-radius: 4px;
    ` : `
    border-radius: 0;
  `}

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding: 6px 14px;
  }
`;
