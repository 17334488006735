import React from 'react';
import { Icon } from '../Icon';
import { executeOnKeyDown } from '../../../utils/keyboardEvents';
import type { NumberPicker as NumberPickerProps } from './numberPicker.types';
import { NumberPlaceholder } from './placeholders/numberPickerPlaceholders';

import {
  NumberPickerWrapper,
  DecrementNumber,
  IncrementNumber,
  Quantity
} from './numberPicker.styles';

/**
 *
 * @param {number} value Initial value.
 * @param {number} [minValue=0] Minimum quantity allowed.
 * @param {boolean} hideControls Hide the controls.
 * @param {string} [labelValue] Displays content of the label.
 * @param {( qty: number ) => void} onChange Callback on quantity change.
 * @param {boolean} [parentIsLoading = false] Is parent still loading data
 */

export const NumberPicker = ({ value, minValue = 0, onChange, hideControls, testid = 'picker', parentIsLoading = false }:NumberPickerProps):React.ReactElement => {

  const onQtyChange = (action:number):void => {
    const newQty = value + action;
    if (newQty >= minValue) onChange(newQty);
  };

  if (parentIsLoading) {
    return (
      <NumberPickerWrapper parentIsLoading data-testid={testid}>
        {!hideControls && <DecrementNumber parentIsLoading>
          <Icon glyph='subtract' title='subtract' width='12px' />
        </DecrementNumber>}
        <Quantity hideControls={hideControls}><NumberPlaceholder /></Quantity>
        {!hideControls && <IncrementNumber parentIsLoading>
          <Icon glyph='add' title='add' width='12px'/>
        </IncrementNumber>}
      </NumberPickerWrapper>
    );
  }

  return (
    <NumberPickerWrapper data-testid={testid}>
      {!hideControls && <DecrementNumber onClick={() => onQtyChange(-1)} onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && onQtyChange(-1)} data-testid='decrease-click' aria-label='Decrease quantity'>
        <Icon glyph='subtract' title='subtract' width='12px' />
      </DecrementNumber>}
      <Quantity hideControls={hideControls}data-testid='current-count'><span className='visually-hidden'>Quantity: </span>{value}</Quantity>
      {!hideControls && <IncrementNumber onClick={() => onQtyChange(1)} onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && onQtyChange(1)} data-testid='increase-click' aria-label='Increase quantity'>
        <Icon glyph='add' title='add' width='12px'/>
      </IncrementNumber>}
    </NumberPickerWrapper>
  );
};
