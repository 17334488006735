import { styled } from 'styled-components';

export const FreeGiftWrapper = styled.div`
  display: block;
`;

export const FreeGiftContent = styled.div`
  background-color: ${props => props.theme.colors.lightestGrey};
  padding: 10px 20px;
  margin: 16px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.headings.small}

  svg {
    color: ${props => props.theme.layout.primary};
    padding-right: 10px;
    height: 21px;
  }
`;
