import { styled } from 'styled-components';

export const TitlePlaceholderWrapper = styled.span`
  svg {
    height: ${ ({ theme }) => `clamp(${theme.lineHeights.lh5}, 3vw, ${theme.lineHeights.lh6})` };
  }
`;

export const ButtonTextPlaceholderWrapper = styled.span`
  svg {
    height: ${({ theme }) => theme.lineHeights.lh1};

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      height: ${({ theme }) => theme.lineHeights.lh2};
    }
  }
`;
