import { styled } from 'styled-components';

export const FieldWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
`;

export const FieldDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  color: ${props => props.theme.colors.darkGrey};
  margin-bottom: 5px;
`;
