import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

export const BenefitIconPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <LoadingPlaceholder
    width={16}
    height={12}
  >
    <rect x="0" y="0" rx="0" ry="0" width="16" height="12" />
  </LoadingPlaceholder>
);

export const BenefitTextPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <LoadingPlaceholder
    width={100}
    height={18}
  >
    <rect x="0" y="3" rx="0" ry="0" width="100" height="12" />
  </LoadingPlaceholder>
);
