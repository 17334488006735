import { styled } from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionItem = styled.div`
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }
`;
