import React from 'react';
import { ConfirmationBanner as ConfirmationBannerProps } from './confirmationBanner.types';

import { Button } from '../Button';
import { Icon } from '../Icon';

import {
  ConfirmationWrapper,
  DescriptionWrapper,
  ConfirmationButton,
  Description,
} from './confirmationBanner.styles';

/**
 * Render a call to action banner
 * @param {React.ReactNode} children Content of the banner
 * @param {import('./ctaBanner.types').ConfirmationBanner.types[]} buttons CTA to prompt user to next specified action
 */

export const ConfirmationBanner = ({ children, buttons, testid='confirmation-banner', id }: ConfirmationBannerProps): React.ReactElement => {
  return (
    <ConfirmationWrapper data-testid={testid} {...(id ? { id } : {})}>
      <DescriptionWrapper>
        <Description>{children}</Description>
      </DescriptionWrapper>
      <ConfirmationButton>
        {buttons.map(button => {
          const { children, icon, id, testid ='confirmation', ...rest } = button;
          return (
            <Button key={id} {...rest} data-testid={testid}>{icon && <Icon glyph={icon} width='13px'/>}{children}</Button>
          );
        })}
      </ConfirmationButton>
    </ConfirmationWrapper>
  );
};
