import React from 'react';

import { Icon } from '../../atoms/Icon';
import { ProgressSteps as ProgressStepsProps } from './progressSteps.types';

import {
  StepsWrapper,
  StepsItem,
  StepsContent
} from './progressSteps.styles';

/**
 * @param {string[]} steps Steps titles.
 * @param {number} currentStep Step selected, besed on index (0, 1, 2, etc..).
 *
 */

export const ProgressSteps = ({ steps, currentStep }: ProgressStepsProps): React.ReactElement => {
  const stepList = steps.map((step, k) => {

    const current = k === currentStep;
    const completedStep = k < currentStep;
    const completed = completedStep ? <Icon width="20" glyph="check_line" title={`step-${k+1} completed`} /> : `${k+1}. `;
    const srMessage = completedStep ? 'completed' : 'pending';

    return (
      <StepsItem key={k} completed={completedStep} data-testid={'step'} {...(current && { 'aria-current': 'true' })} current={current} className={srMessage}>
        <StepsContent>{completed}{`${step}`}<span className="visually-hidden">{srMessage}</span></StepsContent>
      </StepsItem>
    );
  });

  return (
    <StepsWrapper role="list" aria-label="progress" className="step-indicator">
      {stepList}
    </StepsWrapper>
  );
};
