import { styled } from 'styled-components';

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 9px 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.lightBeige};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.fs0};
  line-height: ${({ theme }) => theme.lineHeights.lh0};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-weight: 500;
  }
`;

export const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    gap: 10px;
  }

  &:nth-child(n + 4) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    &:nth-child(n + 4) {
      display: flex;
    }
  }
`;

export const IconWrap = styled.div`
  line-height: 0;
`;
