import React, { useEffect, useRef } from 'react';

import type { Overlay as OverlayProps } from './overlay.types';

import {
  OverlayWrapper,
  OverlayContent,
} from './overlay.styles';

/**
 *
 * @param {React.ReactElement | null} children Content to be shown on the overlay.
 * @param {import('./overlay.types').OverlaySizes} [size='small'] Size of the Overlay.
 * @param {boolean} open If Overlay is visible.
 * @param {string} title Overlay title (accessibility only).
 * @param {() => void} onClose Callback for closing overlay.
 * @param {boolean} [noClickOutside = false] If true, prevent clicking outside content from closing overlay
 *
 */

export const Overlay = ({ id, testid = 'overlay', children, size = 'small', open, title, onClose, noClickOutside = false }:OverlayProps): React.ReactElement => {

  const content = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (open) {
      bodyElement?.classList.add('no-scroll');
      wrapper.current?.classList.add('fade-in');
    } else {
      wrapper.current?.classList.remove('fade-in');
      bodyElement?.classList.remove('no-scroll');
      wrapper.current?.classList.add('fade-out');
      setTimeout(() => {
        wrapper.current?.classList.remove('fade-out');
      }, 300);
    }
  }, [open]);

  const handleClickOutside = (e:React.MouseEvent) => {
    if (content.current && !content.current.contains(e.target as Node) && !noClickOutside) {
      onClose();
    }
  };

  return children ? (
    <OverlayWrapper {...(id ? { id: id } : {})} ref={wrapper} data-testid={testid} onClick={(e:React.MouseEvent) => handleClickOutside(e)}>
      <OverlayContent size={size} role="dialog" data-testid={`${testid}-content`} ref={content} aria-label={title}>
        {children}
      </OverlayContent>
    </OverlayWrapper>
  ) : <div data-testid={testid}/>;
};
