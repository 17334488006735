import React from 'react';
import type { ProductOptionListItem as ProductOptionListItemProps } from './productOptionListItem.types';
import ProductOptionListItemTitlePlaceholder from './placeholders/productOptionListItemTitlePlaceholder';
import ProductOptionListItemSavingPlaceholder from './placeholders/productOptionListItemSavingPlaceholder';
import ProductOptionListItemDescriptionPlaceholder from './placeholders/productOptionListItemDescriptionPlaceholder';
import ProductOptionListItemPricingPlaceholder from './placeholders/productOptionListItemPricingPlaceholder';

import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import {
  ProductOptionListItemWrapper,
  DesktopWrapper,
  OptionTitleWrapper,
  OptionListItemTitle,
  OptionDescription,
  Pricing,
  SubscriptionWrapper,
  SavingPromotion,
  OriginalPrice,
  SubPriceWrapper,
  SavingPromotionWrapper,
  FollowOnPricing,
  InStockBoldText
} from './productOptionListItem.styles';

/**
 *
 * @param {string} [title = ''] Item's title.
 * @param {string} [price = ''] Item's price.
 * @param {string} [followOnPrice] Item's follow on price.
 * @param {string} [humanReadablePricingStringTemplate] Item's follow on pricing template.
 * @param {string} [savingString] Total savings.
 * @param {() => void} [onClick] On click event callback.
 * @param {boolean} [selected=false] Item selected.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading.
 * @param {string} [originalPrice] Provides a strike-through price.
 * @param {boolean} [hasFollowOnPricing] Shows follow on price.
 * @param {boolean} [hasDiscount = false] Indicate if the original price is lower than the current price.
 * @param {boolean} [inStock = true] If item is in stock.
 *
 */

export const ProductOptionListItem = ({ title = '', description, price = '', followOnPrice, humanReadablePricingStringTemplate, savingString, onClick, selected=false, testid = 'product-list-item', parentIsLoading = false, hasFollowOnPricing, originalPrice, hasDiscount = false, inStock = true }: ProductOptionListItemProps): React.ReactElement => {

  const addPricesToTemplate = (): string => {
    const priceTemplate = '{{unitPrice}}';
    const followTemplate = '{{followOnPrice}}';

    if (!humanReadablePricingStringTemplate || humanReadablePricingStringTemplate === '') return `<span>${price}</span>`;

    if(!hasFollowOnPricing) {
      return humanReadablePricingStringTemplate ? humanReadablePricingStringTemplate.replace(priceTemplate, `<span>${price}</span>` || '') : '';
    }
    return humanReadablePricingStringTemplate ? humanReadablePricingStringTemplate.replace(priceTemplate, `<b>${price}</b>` || '').replace(followTemplate, `<b>${followOnPrice}</b>` || '') : '';
  };

  if (parentIsLoading) {
    return (
      <ProductOptionListItemWrapper selected={selected} role='button' tabIndex={0} data-testid={testid} onClick={() => onClick && onClick()} onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => executeOnKeyDown(e) && onClick && onClick()} inStock={inStock}>
        <DesktopWrapper>
          <OptionTitleWrapper>
            <ProductOptionListItemTitlePlaceholder />
            <ProductOptionListItemDescriptionPlaceholder />
          </OptionTitleWrapper>
          <SubscriptionWrapper>
            <ProductOptionListItemPricingPlaceholder isMobile={false} />
            <ProductOptionListItemSavingPlaceholder style={{ marginLeft: '10px' }} />
          </SubscriptionWrapper>
        </DesktopWrapper>
        <ProductOptionListItemPricingPlaceholder isMobile={true} />
      </ProductOptionListItemWrapper>
    );
  }

  return (
    <ProductOptionListItemWrapper selected={selected} role='button' tabIndex={0} data-testid={testid} onClick={() => onClick && onClick()} onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => executeOnKeyDown(e) && onClick && onClick()} inStock={inStock}>
      <DesktopWrapper>
        <OptionTitleWrapper>
          <OptionListItemTitle inStock={inStock}>{title}</OptionListItemTitle>
          {description && inStock && <OptionDescription>{description}</OptionDescription>}
          {!inStock && <InStockBoldText>Out of stock</InStockBoldText>}
        </OptionTitleWrapper>
        <SubscriptionWrapper>
          {(!hasFollowOnPricing && originalPrice && hasDiscount) ?
            <SubPriceWrapper>
              {originalPrice && <OriginalPrice>{originalPrice}</OriginalPrice>}
              <Pricing data-testid={`${testid}-punit`} dangerouslySetInnerHTML={{ __html: addPricesToTemplate() }} inStock={inStock}/>
            </SubPriceWrapper> :
            <FollowOnPricing followOnPrice>
              <Pricing data-testid={`${testid}-followonprice`} dangerouslySetInnerHTML={{ __html: addPricesToTemplate() }} inStock={inStock}/>
            </FollowOnPricing>
          }
          {savingString && !hasFollowOnPricing &&
            <SavingPromotionWrapper>
              <SavingPromotion selected={selected} data-testid={`${testid}-saving`} inStock={inStock}>{savingString}</SavingPromotion>
            </SavingPromotionWrapper>
          }
        </SubscriptionWrapper>
      </DesktopWrapper>
    </ProductOptionListItemWrapper>
  );
};
