import { styled, css } from 'styled-components';
import { transparentize } from 'polished';

export const OverlayWrapper = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndexes.zi4};
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  background-color: ${props => transparentize(0.6, props.theme.colors.black)};
  transition: all 0.3s ease-in-out;

  &.fade-in {
    left: 0;
    visibility: visible;
  }

  &.fade-out {
    left: 100vw;
    visibility: hidden;
  }

  @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
    display: flex;
    opacity: 0;
    left: 0;

    &.fade-in {
      left: 0;
      opacity: 1;
      visibility: visible;
    }

    &.fade-out {
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const OverlayContent = styled.div<{ size: string}>`
  position: relative;
  max-width: 340px;
  min-height: 10px;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    max-width: 569px;
  }

  ${props => props.size === 'big'
    && css`
    max-width: none;

    @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
      max-width: 768px;
    }
  `}
`;

export const OverlayCloseWrapper = styled.div`
  width: 100%;
  text-align: right;
`;
