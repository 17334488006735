import React, { useState, useEffect } from 'react';

import { FormField, DropDown, Button } from '@arcade/component-library';
import type { Site } from '../CampaignForm/campaignForm';
import { siteUrls } from '../../lib/sites';

import {
  PreviewWrapper,
  PanelControls,
  DropWrapper,
  PanelPreview,
  PanelIframe
} from './previewPanel.styles';

const PreviewPanel = ({ sites, campaignId, disabled, iFrameKey }: { sites: Site[], campaignId?:string, disabled:boolean, iFrameKey:string }):React.ReactElement => {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [dropOptions, setDropOptions] = useState<{ label:string, value:string }[]>([]);

  useEffect(() => {
    const options = sites.map(site => ({ label: site.name || '', value: site.code || '' }));
    setDropOptions(options);
  }, [sites]);

  const handleSiteSelection = (event: React.ChangeEvent<HTMLSelectElement>):void => {
    const dropValue = event.target.value;
    const urlObj = siteUrls[dropValue];
    const url = `${urlObj.baseUrl}/api/draft?secret=${process.env.NEXTJS_DRAFT}&slug=${urlObj.pageUrl}?campaignId=${campaignId}`;
    setPreviewUrl(url);
  };

  const handleNewWindow = ():void => {
    window.open(previewUrl, '_blank');
  };

  return (
    <PreviewWrapper>
      <PanelControls>
        <DropWrapper>
          <FormField id='site-dropdown' label='Preview Site' required>
            {dropOptions.length > 0 && <DropDown testid='site-select' id='site-dropdown' name='preview-site' placeholder='Please select a site' options={dropOptions} onChange={handleSiteSelection} disabled={disabled}/>}
          </FormField>
        </DropWrapper>
        <Button testid='new-window' disabled={previewUrl === undefined || disabled} onClick={handleNewWindow}>Open in new window</Button>
      </PanelControls>
      {previewUrl && <PanelPreview>
        <PanelIframe key={iFrameKey} title='preview' src={previewUrl} />
      </PanelPreview>}
    </PreviewWrapper>
  );
};

export default PreviewPanel;
