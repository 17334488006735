import { styled, css } from 'styled-components';
import type { Source } from '../image.types';
import { getDimension } from '../image';

export const ImagePlaceholderWrapper = styled('span').withConfig({
  shouldForwardProp: props => !['sources'].includes(props)
})<{ sources?: Source[] }>`
  svg {
    margin: 0 auto;
  }
  ${({ sources }) => sources && sources?.length && sources.map(source => css`
    .im${source.minWidth} {
      display: none;
    }

    @media (min-width: ${source.minWidth}) {
      .im${source.minWidth} {
        display: inline-block;
      }
      ${sources.map(sourceTwo => getDimension(sourceTwo.minWidth) < getDimension(source.minWidth) && css`
        .im${sourceTwo.minWidth} {
          display: none;
        }
      `)}

      .base {
        display: none;
      }
    }
  `)}
`;
