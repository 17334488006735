const monthsAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 *
 * @param {string} timestamp timestamp to be formatted.
 * @returns {string} formatted date.
 */
export const timestampToDate = (timestamp:string):string => {
  const date = new Date(timestamp);
  if(date instanceof Date && !isNaN(date.valueOf())) {
    return `${date.getDate()} ${monthsAbbr[date.getMonth()]} ${date.getFullYear()}`;
  }
  return 'N/A';
};
