import React from 'react';

import type { Tag as TagProps } from './tag.types';

import { Icon } from '../Icon';

import {
  TagWrapper
} from './tag.styles';

/**
 *
 * @param {string} id Tag id.
 * @param {string} text Tag label.
 * @param {(_id:string) => void} onRemove Handle click/remove.
 *
 */

export const Tag = ({ id, text, onRemove, testid = 'tag' }:TagProps): React.ReactElement => {
  const handleRemove = (e:React.MouseEvent, id:string) => {
    e.preventDefault();
    onRemove(id);
  };

  return (
    <TagWrapper onClick={(e:React.MouseEvent) => handleRemove(e, id)} data-testid={`${testid}-${id}`}>
      <span>{text}</span>
      <Icon glyph='close'/>
    </TagWrapper>
  );
};
