import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useUserRole = () => {
  const { getIdTokenClaims } = useAuth0();
  const [isDeveloper, setIsDeveloper] = useState(false);
  const developerRole = process.env.AUTH0_ROLE_DEVELOPER;

  useEffect(() => {
    (async () => {
      const claims = await getIdTokenClaims();
      const key = process.env.AUTH0_ROLE_CLAIM_ID;

      if (!key || !claims || !claims[key]) {
        setIsDeveloper(false);
        return;
      }

      setIsDeveloper(claims[key].includes(developerRole));
    })();

  }, []);

  return { isDeveloper, developerRole };
};

export default useUserRole;
