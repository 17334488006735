import { styled, css } from 'styled-components';
import { hideVisually } from 'polished';

export const CheckboxContainer = styled.label`
  cursor: pointer;
  display: flex;
`;

export const CheckboxHidden = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually()}
`;

export const CheckboxWrapper = styled('span').withConfig({
  shouldForwardProp: props =>
    !['focused', 'error'].includes(props)
})<{ disabled: boolean, focused: boolean, checked: boolean, error: boolean }>`
  width: 18px;
  height: 18px;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  margin-top: 3px;
  background-color: ${props => props.theme.colors.white};
  ${props => props.error && css`
  border: 2px solid ${props => props.theme.colors.error};
  `}

  &::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    top: -2px;
    left: -2px;
    ${props => props.focused && css`
      border: 1px solid ${props => props.theme.colors.focus};
    `}
  }

  ${props => props.disabled && css`
    border-color: ${props.theme.colors.grey};
    background-color: ${props.theme.colors.lightestGrey};
    cursor: default;
  `}

  ${props => props.focused && css`
    border: 1px solid ${props.theme.colors.focus};
  `}

  ${props => props.checked && css`
    border-color: ${props.theme.colors.black};
    background-color: ${props.theme.colors.black};
  `}
`;

export const IconWrapper = styled.span<{ checked: boolean }>`
  display: block;
  visibility: ${props => props.checked ? 'visible' : 'hidden'};
  line-height: 16px;
  font-size: ${props => props.theme.fontSizes.fs0};

  svg {
    color: ${props => props.theme.colors.white};
  }
`;

export const LabelText = styled.span<{disabled: boolean}>`
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  width: 100%;
  padding-left: 10px;
  color: ${props => props.theme.colors.black};
  ${props => props.disabled && css`
    color: ${props => props.theme.colors.grey};
    cursor: default;
  `}
`;

export const LabelWrapper = styled.span`
  position: relative;
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
`;

export const Required = styled.span`
  position: absolute;
  top: 0;
  left: 5px;
  line-height: 14px;
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs1};
`;
