import React from 'react';

import type { LoadMore as LoadMoreProps } from './loadMore.types';

import {
  Container,
  ShowingItemsText,
  Bold,
  ProgressBarOuter,
  ProgressBarInner,
  LoadMoreButton
} from './loadMore.styles';

/**
 * @param {string} [buttonText = 'Load more'] Text on button
 * @param {number} showingItems Number of items showing
 * @param {number} totalItems Total number of items that could be shown
 * @param {() => void} onButtonClick Function to call when load more button clicked
 */

export const LoadMore = ({ buttonText = 'Load more', showingItems, totalItems, onButtonClick }: LoadMoreProps): React.ReactElement => {
  return (
    <Container>
      <ShowingItemsText>Showing <Bold>{showingItems}</Bold> of <Bold>{totalItems}</Bold> items</ShowingItemsText>
      <ProgressBarOuter>
        <ProgressBarInner progress={showingItems / totalItems * 100} />
      </ProgressBarOuter>
      <LoadMoreButton onClick={onButtonClick} btnType='outlined' disabled={showingItems === totalItems}>{buttonText}</LoadMoreButton>
    </Container>
  );
};
