import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import type { Source } from '../image.types';
import { ImagePlaceholderWrapper } from './styles';
import { getDimension } from '../image';

interface ImagePlaceholderProps extends IContentLoaderProps {
  id: string
  sources?: Source[]
}

const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({ id, width, height, sources, ...rest }): React.ReactElement => {
  const filteredSources = sources?.filter(source => !!source.minWidth && !!source.imageWidth && !!source.imageHeight);

  // Maps the sources into placeholders and only shows the relevant one (sources could be different size)
  return (
    <ImagePlaceholderWrapper sources={filteredSources}>
      <LoadingPlaceholder id={`${id}-main`} className='base' viewBox={`0 0 ${width} ${height}`} {...rest}>
        <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
      </LoadingPlaceholder>
      {!!filteredSources && filteredSources.map((source, i) => (
        <LoadingPlaceholder id={`${id}-src-${i}`} key={source.minWidth} className={`im${source.minWidth}`} viewBox={`0 0 ${getDimension(source.imageWidth)} ${getDimension(source.imageHeight)}`} {...rest}>
          <rect x="0" y="0" rx="0" ry="0" width={getDimension(source.imageWidth)} height={getDimension(source.imageHeight)} />
        </LoadingPlaceholder>
      ))}
    </ImagePlaceholderWrapper>
  );
};

export default ImagePlaceholder;
