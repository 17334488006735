
import React from 'react';
import type { IContentLoaderProps as LoadingPlaceholderType } from './loadingPlaceholder.types';
import ContentLoader from 'react-content-loader';

import { LoadingPlaceholderWrapper } from './loadingPlaceholder.styles';

/**
 *
 * @param {string} id string to be passed as uniqueKey to avoid SSR issues
 * @param {string | number | undefined} width width of SVG
 * @param {string | number | undefined} height height of SVG
 * @param {string | undefined} viewBox viewBox of SVG
 * @param {string | undefined} backgroundColor background colour of animation
 * @param {string | undefined} foregroundColor foreground colour of animation
 * @param {number | undefined} speed speed of the animation
 * @param {LoadingPlaceholderType['style']} style styles to add to SVG
 *
 */

export const LoadingPlaceholder: React.FC<LoadingPlaceholderType> = ({
  id,
  width,
  height,
  speed = 2,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
  viewBox,
  style,
  ...rest
}): React.ReactElement => {
  return (
    <LoadingPlaceholderWrapper>
      <ContentLoader
        uniqueKey={id}
        style={{ ...style }}
        speed={speed}
        width={width}
        height={height}
        viewBox={viewBox || `0 0 ${width} ${height}`}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        {...rest}
      />
    </LoadingPlaceholderWrapper>
  );
};
