import React from 'react';

import type { RowColumns as RowColumnsProps } from './rowColumns.types';
import { RowColumnsWrapper } from './rowColumns.styles';

/**
 *
 * @param {JSX.Element[]} children Array of items to show in a grid.
 * @param {number} rowColSpan Number of items per row.
 */

export const RowColumns = ({ children, rowColSpan }: RowColumnsProps): React.ReactElement => (
  <RowColumnsWrapper data-testid={`colspan-${rowColSpan}`} rowColSpan={rowColSpan}>
    {children}
  </RowColumnsWrapper>
);
